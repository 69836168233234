import { Box, Grid, Paper } from '@mui/material';
import { LogoTop } from 'parts/LogoTop';

interface IAuthRootWrapper {
  bgImg: string;
}
export const AuthRootWrapper: React.FC<IAuthRootWrapper> = ({
  children,
  bgImg,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Paper elevation={3} sx={{ width: 300, p: 5 }} component="form">
            <LogoTop />
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
