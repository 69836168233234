import { MentoringSheet } from 'types';

type SheetsState = {
  currentId: string | null;
  sheets: MentoringSheet[];
};

export const MENTORING_SHEETS_ACTION_TYPE = {
  CREATE: 'create',
  DELETE: 'delete',
  SElECT: 'select',
  EDIT: 'edit',
  LIST: 'list',
} as const;

export const mentoringSheetReducer = (
  state: SheetsState,
  action:
    | {
        type: typeof MENTORING_SHEETS_ACTION_TYPE.CREATE;
        sheet: MentoringSheet;
      }
    | {
        type: typeof MENTORING_SHEETS_ACTION_TYPE.DELETE;
      }
    | {
        type: typeof MENTORING_SHEETS_ACTION_TYPE.SElECT;
        id: string;
      }
    | {
        type: typeof MENTORING_SHEETS_ACTION_TYPE.EDIT;
        fracSheet: Partial<MentoringSheet>;
      }
    | {
        type: typeof MENTORING_SHEETS_ACTION_TYPE.LIST;
        sheets: MentoringSheet[];
      }
): SheetsState => {
  const type = action.type;
  switch (type) {
    case MENTORING_SHEETS_ACTION_TYPE.CREATE: {
      if (state.sheets === undefined) {
        return state;
      }
      const sheet = action.sheet;
      const nextState: SheetsState = {
        currentId: sheet.id,
        sheets: [sheet, ...state.sheets],
      };
      return nextState;
    }
    case MENTORING_SHEETS_ACTION_TYPE.EDIT: {
      const currentSheet = state.sheets.find(s => s.id === state.currentId);
      if (currentSheet === undefined) {
        throw new Error(
          `currentId ${state.currentId} is not found in state.sheets`
        );
      }
      const newSheet: MentoringSheet = { ...currentSheet, ...action.fracSheet };
      const newSheets = [...state.sheets].map(s => {
        if (s.id === newSheet.id) {
          return newSheet;
        }
        return s;
      });
      const nextState: SheetsState = {
        currentId: state.currentId,
        sheets: newSheets,
      };
      return nextState;
    }
    case MENTORING_SHEETS_ACTION_TYPE.DELETE: {
      // sheets, currentId => nextSheets, nextCurrentId
      // [0, 1, 2] , 1 => [0, 2], 0
      // [0, 1] , 0 => [1], 0
      // [0] , 0 => [], null
      const ix = state.sheets.findIndex(s => s.id === state.currentId);
      const newIx = state.sheets.length <= 1 ? null : Math.max(ix - 1, 0);
      const newSheets = [...state.sheets].filter(s => s.id !== state.currentId);
      const newId = newIx === null ? null : newSheets[newIx].id;
      const nextState: SheetsState = {
        currentId: newId,
        sheets: newSheets,
      };
      return nextState;
    }
    case 'select': {
      const nextState: SheetsState = {
        currentId: action.id,
        sheets: state.sheets,
      };
      return nextState;
    }
    case MENTORING_SHEETS_ACTION_TYPE.LIST: {
      const sheets = action.sheets;
      const currentId = sheets.length > 0 ? sheets[0].id : null;
      const nextState: SheetsState = {
        currentId: currentId,
        sheets: sheets,
      };
      return nextState;
    }
    default: {
      const never: never = type;
      throw new Error('type ' + type + ' is not valid.');
    }
  }
};
