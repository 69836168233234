import React from 'react';
import { Box, Button, TableCell, TableRow } from '@mui/material';
import { IAsset } from 'types/asset';
import { AssetFirstColumn } from './FrozenColumnTableCell';
import { columnOrder } from './columnOrder';
import { NumberTableCell } from './NumberTableCell';
import { StringTableCell } from './StringTableCell';
import { formatDate } from 'utils';

interface AssetTableRowProps {
  asset: IAsset;
  checkboxSelection: boolean;
  checked?: boolean;
  selectedAssets?: IAsset[];
  onSelectAsset?: (asset: IAsset) => void;
  onClickProductName?: (bbgDocId: string) => void;
  detailVisible: boolean;
}

export const AssetTableRow = ({
  asset,
  checkboxSelection,
  checked,
  onSelectAsset,
  onClickProductName,
  detailVisible,
}: AssetTableRowProps) => {
  return (
    <TableRow hover tabIndex={-1}>
      <AssetFirstColumn
        checkboxSelection={checkboxSelection}
        onClickCheckbox={
          onSelectAsset !== undefined
            ? event => onSelectAsset(asset)
            : undefined
        }
        checked={checked}
      >
        {asset.bbgDocId &&
        asset.bbgDocId.length > 0 &&
        onClickProductName !== undefined ? (
          <Button
            variant="text"
            sx={{
              textAlign: 'left',
              textTransform: 'inherit',
              textDecoration: 'underline',
              py: 0,
              px: 1,
            }}
            onClick={() => onClickProductName(asset.bbgDocId!)}
          >
            {asset[columnOrder[0].key]}
          </Button>
        ) : (
          <Box sx={{ pl: 1 }}>{asset[columnOrder[0].key]}</Box>
        )}
      </AssetFirstColumn>
      {columnOrder
        .slice(1)
        .filter(c => !c.isDetail || detailVisible)
        .map(c =>
          c.valueType === 'number' ? (
            <NumberTableCell
              key={c.key}
              value={asset[c.key]}
              padding={c.padding}
              align={c.align}
              decimalPoints={
                c.decimalPoints === 'cent'
                  ? asset.currency === 'JPY'
                    ? 0
                    : 2
                  : c.decimalPoints
              }
            />
          ) : c.valueType === 'Date' ? (
            <TableCell key={c.key} padding={c.padding} align={c.align}>
              {asset[c.key] !== undefined && asset[c.key] !== null
                ? formatDate(asset[c.key], '%-m月%d日')
                : '-'}
            </TableCell>
          ) : (
            <StringTableCell
              key={c.key}
              align={c.align}
              padding={c.padding}
              sx={{ py: 0, px: 1 }}
            >
              {asset[c.key]}
            </StringTableCell>
          )
        )}
    </TableRow>
  );
};
