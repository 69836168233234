import {
  Box,
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { AuthTextField } from 'components/AuthComponents';
import React, { useState } from 'react';
import { useResetPassword } from './hooks';
import { Link as RouterLink } from 'react-router-dom';
import { LogoTop } from 'parts/LogoTop';

export const ResetPasswordPage = () => {
  const { email, sending, success, onChange, sendResetEmail, errorMessage } =
    useResetPassword();
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
        <Stack
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: 'sm' }}
        >
          <LogoTop />
          <Typography variant="h6" component="h2" sx={{ mt: 8, mb: 4 }}>
            パスワードの再設定
          </Typography>
          <AuthTextField
            label="ご登録のメールアドレス"
            value={email}
            handleChange={onChange}
            errorMessage={errorMessage}
          />
          {success ? (
            <>
              <Typography sx={{ mt: 16 }}>
                パスワードリセット用メールを送信しました。noreply@system.pragmaworks.co.jp
                からのメールが見つからない場合、スパムフォルダーもご確認ください。不明点がある場合、担当にご連絡ください。
              </Typography>
              <Link component={RouterLink} to="/signin" variant="caption">
                ログイン画面に戻る
              </Link>
            </>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={sendResetEmail}
            >
              {sending ? (
                <CircularProgress sx={{ color: 'white' }} />
              ) : (
                '送信する'
              )}
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};
