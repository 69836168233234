import { Box, Link, SxProps } from '@mui/material';

export const LinKToHelp = ({ sx }: { sx?: SxProps }) => (
  <Box sx={{ display: 'flex', ...sx }} justifyContent="center">
    <Link
      href="https://knowledge.pragmaworks.co.jp/アカウント-セキュリティ"
      variant="caption"
    >
      お困りですか？
    </Link>
  </Box>
);
