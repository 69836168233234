import { Loading, ReportPresentation } from 'components';
import { useStore } from 'contexts';
import {
  useExchangeRates,
} from 'hooks';

export const AdminFamilyReportPage = () => {
  const { familyInfo } = useStore()
  const exchangeRates = useExchangeRates();
  if (familyInfo.status === 'idle' || familyInfo.status === 'loading') {
    return <Loading />;
  }
  return (
    <ReportPresentation
      clientOrFamily={familyInfo.data?.family!}
      assets={familyInfo.data?.assets ?? []}
      barData={familyInfo.data?.historicalAggregationByAssetTypeMonthly ?? []}
      exchangeRates={exchangeRates}
    />
  );
};


