import { commaize } from 'utils/commaize';

interface NetWorthProgressBarProps {
  netWorthGoal: number;
  currentNetWorth: number;
  eps?: number;
}

export const NetWorthProgressBar = ({
  netWorthGoal,
  currentNetWorth,
  eps = 1e-6,
}: NetWorthProgressBarProps) => (
  <div>
    <div style={{ fontSize: '1rem', marginBottom: '10px', display: 'flex' }}>
      <div style={{ color: '#BF953F', fontWeight: 'bold' }}>
        <div>目標額：　</div>
        <div>現在の資産時価総額：　</div>
      </div>
      <div>
        <div>
          {netWorthGoal === 0
            ? 'G-POP\u{00AE}タブから目標金額を設定してください'
            : commaize(netWorthGoal)}
        </div>
        <div>{commaize(currentNetWorth)}</div>
      </div>
    </div>
    {netWorthGoal > 0 && (
      <div
        style={{
          backgroundColor: '#ededed',
          position: 'relative',
          height: '40px',
          borderRadius: '30px',
          margin: '4px, 0',
        }}
      >
        <div
          style={{
            background: 'linear-gradient(45deg, #BF953F, #FCF6BA)',
            height: '40px',
            borderRadius: `${
              currentNetWorth >= netWorthGoal ? '15px' : '15px 0 0 15px'
            }`,
            width: `${Math.min((100 * currentNetWorth) / (netWorthGoal + eps), 100)}%`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: '10px',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {`${Math.round((100 * currentNetWorth) / (netWorthGoal + eps))}%`}
          </span>
        </div>

        {/* <div
        style={{
          textAlign: "end",
          position: "relative",
          top: 0,
          left: 0,
        }}>100%
        <div
          style={{
            position: "absolute",
            top: 0,
            left: "98%",
          }}>
        </div>
      </div> */}
      </div>
    )}
  </div>
);
