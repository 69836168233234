// export const commaize = makeCommaize(0)
export const commaize = (value: number, decimalPlace: number = 0) => {
  const result = value
    .toFixed(decimalPlace)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); //TODO 2 in toFixed(2) is a magick number.
  return result;
};
export const nullcommaize = (value: number | null | undefined): string => {
  if (value === undefined || value === null) {
    return '-';
  }
  return commaize(value);
};

export const mil = (value: number) => {
  const newValue = value / 10 ** 6;
  const commaized = commaize(newValue, 0);
  return commaized;
};
