import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {
  getClientById,
  getClients,
  getFamilyById,
  getAssetsByClientId,
  getAssetsByFamilyId,
  getClientFinancialSummary,
  getFamilyFinancialSummary,
  AssetSnapshotCommon,
  ProductTypeObj,
  list,
  getClientAssetHistoryAggregationByAssetType,
  getFamilyAssetHistoryAggregationByAssetType,
} from 'api';
import { useAuth } from 'hooks';
import {
  Family,
  IAsset,
  FinancialSummary,
  NewApiClient,
  ClientAssetHistoryAggregationByAssetType,
  FamilyAssetHistoryAggregationByAssetType,
  TotalAsset,
} from 'types';
import { useLocation } from 'react-router-dom';

const storeContext = createContext<{
  clientInfo: WithNetworkStatus<ClientInfo>;
  familyInfo: WithNetworkStatus<FamilyInfo>;
  selectClient: (id: string) => Promise<NewApiClient | undefined>;
  selectFamily: (id: string) => Promise<void>;
}>({
  clientInfo: { status: 'idle', data: undefined, error: null },
  familyInfo: { status: 'idle', data: undefined, error: null },
  selectClient: () => Promise.resolve(undefined),
  selectFamily: () => Promise.resolve(undefined),
});

export const useStore = () => useContext(storeContext);

type WithNetworkStatus<T> = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  data: T | undefined;
};

type ClientInfo = {
  client: NewApiClient;
  assets: IAsset[];
  // summary: FinancialSummary
  historicalAggregationByAssetTypeMonthly: ClientAssetHistoryAggregationByAssetType[];
  assetSummary: TotalAsset;
};

type FamilyInfo = {
  family: Family;
  assets: IAsset[];
  // summary: FinancialSummary
  clients: NewApiClient[];
  historicalAggregationByAssetTypeMonthly: FamilyAssetHistoryAggregationByAssetType[];
};

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [clientInfo, setClientInfo] = useState<WithNetworkStatus<ClientInfo>>({
    status: 'idle',
    error: null,
    data: undefined,
  });
  const [familyInfo, setFamilyInfo] = useState<WithNetworkStatus<FamilyInfo>>({
    status: 'idle',
    error: null,
    data: undefined,
  });

  const twoYearsAgo = new Date(
    new Date().setFullYear(new Date().getFullYear() - 2)
  ).toISOString();
  const today = new Date().toISOString();

  const selectFamily = useCallback(
    async (familyId: string) => {
      if (!currentUser) {
        return; // TODO: How to handle this unexpected path?
      }
      setFamilyInfo({ status: 'loading', error: null, data: undefined });
      try {
        const idToken = await currentUser.getIdToken();
        const [
          family,
          clients,
          assets,
          historicalAggregationByAssetTypeMonthly,
        ] = await Promise.all([
          getFamilyById({ id: familyId, token: idToken }),
          getClients(idToken, familyId),
          // getFamilyFinancialSummary({ id: familyId, token: idToken }),
          getAssetsByFamilyId({ id: familyId, token: idToken }),
          getFamilyAssetHistoryAggregationByAssetType({
            id: familyId,
            token: idToken,
            freq: 'monthly',
            start_at: twoYearsAgo,
            end_at: today,
          }),
        ]);
        setFamilyInfo({
          status: 'succeeded',
          error: null,
          data: {
            family,
            clients,
            assets,
            historicalAggregationByAssetTypeMonthly,
          },
        });
      } catch (e) {
        setFamilyInfo({
          status: 'failed',
          error: e instanceof Error ? e.message : 'Unknown error',
          data: undefined,
        });
      }
    },
    [location.pathname, currentUser]
  );

  const selectClient = useCallback(
    async (clientId: string) => {
      if (!currentUser) {
        return; // TODO: How to handle this unexpected path?
      }
      setClientInfo({ status: 'loading', error: null, data: undefined });
      try {
        const idToken = await currentUser.getIdToken();
        const [
          client,
          assets,
          assetSummary,
          historicalAggregationByAssetTypeMonthly,
        ] = await Promise.all([
          getClientById({ id: clientId, token: idToken }),
          getAssetsByClientId({ clientId: clientId, token: idToken }),
          getClientFinancialSummary({ id: clientId, token: idToken }),
          getClientAssetHistoryAggregationByAssetType({
            id: clientId,
            token: idToken,
            freq: 'monthly',
            start_at: twoYearsAgo,
            end_at: today,
          }),
        ]);
        setClientInfo({
          status: 'succeeded',
          error: null,
          data: {
            client,
            assets,
            assetSummary,
            historicalAggregationByAssetTypeMonthly,
          },
        });
        if (client.familyId) {
          selectFamily(client.familyId);
        } else {
          setFamilyInfo({ status: 'succeeded', error: null, data: undefined });
        }
        return client;
      } catch (e) {
        setClientInfo({
          status: 'failed',
          error: e instanceof Error ? e.message : 'Unknown error',
          data: undefined,
        });
      }
    },
    [location.pathname, currentUser]
  );

  const initialize = async () => {
    if (currentUser === undefined) {
      return;
    }
    if (currentUser === null) {
      setClientInfo({ status: 'idle', error: null, data: undefined });
      setFamilyInfo({ status: 'idle', error: null, data: undefined });
      return;
    }

    let idToken;
    let client;
    try {
      idToken = await currentUser.getIdToken();
      client = await getClientById({ id: currentUser.uid, token: idToken });
    } catch (e) {
      setClientInfo(prev => ({
        status: 'idle',
        error: e instanceof Error ? e.message : 'Unknown error',
        data: undefined,
      }));
      setFamilyInfo(prev => ({
        status: 'idle',
        error: e instanceof Error ? e.message : 'Unknown error',
        data: undefined,
      }));
      return;
    }
    if (client?.familyId !== undefined) {
      selectFamily(client.familyId);
    }
  };

  useEffect(() => {
    if (familyInfo.status == 'idle') {
      initialize();
    }
  }, [currentUser]);

  const contextValue = {
    clientInfo,
    familyInfo,
    selectClient,
    selectFamily,
  };

  return (
    <storeContext.Provider value={contextValue}>
      {children}
    </storeContext.Provider>
  );
};
