import React from 'react';
import { TableCell, TableCellProps } from '@mui/material';
import { commaize } from 'utils';

type SignAwareTableCellProps = {
  value: number | null | undefined;
  align: TableCellProps['align'];
  padding: TableCellProps['padding'];
  decimalPoints: number;
};

export const NumberTableCell = ({
  value,
  align,
  padding,
  decimalPoints,
}: SignAwareTableCellProps) => {
  const isNegative = value && value < 0;
  return (
    <TableCell
      sx={{ color: isNegative ? 'red' : undefined, px: 1, py: 0 }}
      align={align}
      padding={padding}
    >
      {value !== null && value !== undefined
        ? commaize(value, decimalPoints)
        : ''}
    </TableCell>
  );
};
