import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { isDeprecatedSheet } from 'hooks';
import { db } from 'initFirebase';
import {
  DeprecatedMentoringsheet,
  MentoringSheet,
  NewMentoringSheetForm,
} from 'types';

export const createMentoringsheet = async (
  sheetForm: NewMentoringSheetForm
) => {
  const ref = await addDoc(collection(db, 'mentoringSheets'), sheetForm);
  return ref;
};

export const listMentoringsheets = async (
  clientId: string,
  onlyPublished: boolean
) => {
  const queryConstraints = [];

  queryConstraints.push(where('clientId', '==', clientId));
  queryConstraints.push(orderBy('createdAt', 'desc'));

  let q = query(
    collection(db, 'mentoringSheets'),
    ...queryConstraints
  ).withConverter<MentoringSheet>(mentoringSheetConverter);

  const snapshot = await getDocs(q);
  const sheets = snapshot.docs
    .map(d => d.data())
    .filter(s => {
      if (!onlyPublished || isDeprecatedSheet(s)) {
        return true;
      } else {
        return !s.isDraft;
      }
    });
  return sheets;
};

export const updateMentoringsheet = (sheet: MentoringSheet) => {
  const { id, ...data } = sheet;
  try {
    const docToUpdate = doc(db, 'mentoringSheets', id);
    return updateDoc(docToUpdate, data);
  } catch (e) {
    throw e as Error;
  }
};

export const deleteMentoringsheet = (id: string) => {
  try {
    return deleteDoc(doc(db, 'mentoringSheets', id));
  } catch (e) {
    throw e;
  }
};

const mentoringSheetConverter = {
  toFirestore: (mentoringSheet: MentoringSheet) => {
    const { id, ...sheetData } = mentoringSheet;
    return sheetData;
  },

  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions | undefined
  ): MentoringSheet => {
    const { timestamp, createdAt, updatedAt, meetingDate, ...others } =
      snapshot.data(options);
    if (timestamp) {
      return {
        id: snapshot.id,
        createdAt: (createdAt as Timestamp).toDate(),
        meetingDate: (meetingDate as Timestamp | undefined)?.toDate() || null,
        timestamp: (timestamp as Timestamp | undefined)?.toDate() || null,
        ...others,
      } as DeprecatedMentoringsheet;
    }
    return {
      id: snapshot.id,
      createdAt: (createdAt as Timestamp).toDate(), // some dummy doc doesn't have createdAt in dev env.
      meetingDate: (meetingDate as Timestamp | undefined)?.toDate() || null,
      ...(updatedAt && { updatedAt: (updatedAt as Timestamp).toDate() }),
      ...others,
    } as NewMentoringSheetForm & { id: string; updatedAt?: Date };
  },
};
