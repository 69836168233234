import { Box, Typography, Divider } from '@mui/material';

export const Title = ({ titleName }: { titleName: string }) => (
  <Box sx={{ mt: 6 }}>
    <Typography
      color="primary"
      sx={{ fontWeight: 'bold', mb: 0.5, fontSize: { xs: 16, sm: 20 } }}
    >
      {titleName}
    </Typography>
    <Divider
      sx={{
        backgroundColor: theme => theme.palette.primary.main,
        height: '3px',
        mb: 3, // TODO: margin should be set from outside
      }}
    />
  </Box>
);
