import { Box } from '@mui/material';
import { useDownloadExcel } from 'hooks';
import {
  filterAssets,
  useAssetFilter,
} from 'components/AssetFilter/useAssetFilter';
import { AssetTable } from 'components/AssetTable';
import { useAssetTableSorter } from 'components/AssetTable/useAssetTableSorter';
import { FinancialProductDetailDialog } from 'components/FinancialProductDetailDialog/FinancialProductDetailDialog';
import { useFinancialProductDetailDialog } from 'components/FinancialProductDetailDialog/useFinancialProductDetailDialog';
import { Loading } from 'components/Loading';
import {
  useAssets,
  useClientV0,
  useSingleSelect,
  useSnackbar,
  useToggle,
} from 'hooks';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IAsset } from 'types/asset';
import { AssetAdditionDialog } from './AssetAdditionDialog';
import { AssetMenu } from './AssetMenu';
import { AssetFilter } from 'components/AssetFilter/AssetFilter';
import { deleteAsset } from './deleteAsset';
import { DelProductDialog } from './DelProductDialog';
import { AssetTableToggle } from 'components/AssetTableToggle';

export const AssetsPage = () => {
  const { clientId } = useParams();
  // const { client } = useClient(clientId)
  const [detailVisible, toggleDetailVisible] = useToggle(false);
  const [soldVisible, toggleSoldVisible] = useToggle(false);
  // const { assets, isLoading, error } = useAssets()
  const [
    { assets, status, error },
    { fetchAssetsByClientId, createAsset, replaceAsset, removeAsset },
  ] = useAssets();
  const { order, orderBy, buildSorter } = useAssetTableSorter();
  const [selectedAssets, selectAction] = useSingleSelect<IAsset>(
    (a, b) => a.id === b.id
  );
  const [open, product, isLoadingDetail, detailError, action] =
    useFinancialProductDetailDialog();
  const [openAddProduct, toggleOpenAddProduct] = useToggle(false);
  const [openDelProduct, toggleOpenDelProduct] = useToggle(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [filterOptions, setFilterOptions] = useAssetFilter();
  const [downloadExcelState, { downloadExcelByClientId }] = useDownloadExcel();

  const showAddSuccessSnackbar = useCallback(() => {
    showSuccessSnackbar(`追加しました`);
  }, []);
  const showEditSuccessSnackbar = useCallback(() => {
    showSuccessSnackbar(`編集しました`);
  }, []);
  const showAssetErrorSnackbar = useCallback(() => {
    showErrorSnackbar('エラーが発生しました。');
  }, []);
  const downloadClientExcel = () => {
    if (clientId === undefined) throw new Error('clientId is undefined');
    return downloadExcelByClientId(clientId, orderBy, order);
  };

  useEffect(() => {
    if (status === 'idle' && clientId !== undefined) {
      fetchAssetsByClientId({ clientId });
    }
  }, [status]);

  useEffect(() => {
    if (clientId !== undefined) {
      fetchAssetsByClientId({ clientId });
    }
  }, [clientId]);

  const filterdAssets = filterAssets(assets, filterOptions);

  return (
    <>
      <Box sx={{ px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <AssetMenu
            selectedAsset={selectAction.getItem()}
            toggleOpenAddProduct={toggleOpenAddProduct}
            toggleOpenDeleteProduct={toggleOpenDelProduct}
            downloadExcel={downloadClientExcel}
            downloadExcelStatus={downloadExcelState.status}
            downloadExcelErrorMessage={downloadExcelState.errorMessage}
          />
          <AssetFilter
            allRows={assets!}
            setTableFilterOptions={setFilterOptions}
          />
          <AssetTableToggle
            toggleDetailVisible={toggleDetailVisible}
            toggleSoldVisible={toggleSoldVisible}
          />
        </Box>
        {status === 'loading' ? (
          <Loading />
        ) : status === 'failed' ? (
          error
        ) : (
          <AssetTable
            selectedAssets={selectedAssets}
            select={selectAction.select}
            checkboxSelection
            onClickProductName={action.setBbgDocId}
            detailVisible={detailVisible}
            soldVisible={soldVisible}
            order={order}
            orderBy={orderBy}
            buildSorter={buildSorter}
            assets={filterdAssets}
          />
        )}
      </Box>
      <FinancialProductDetailDialog
        open={open}
        product={product}
        isLoading={isLoadingDetail}
        error={detailError}
        close={action.close}
      />
      <AssetAdditionDialog
        open={openAddProduct}
        closeDialog={toggleOpenAddProduct}
        showSuccessAddSnackbar={showAddSuccessSnackbar}
        showSuccessEditSnackbar={showEditSuccessSnackbar}
        showErrorSnackbar={showAssetErrorSnackbar}
        product={selectAction.getItem() || null}
        unselectProduct={selectAction.unselect}
        createAsset={createAsset}
        putAsset={replaceAsset}
      />
      {selectAction.getItem() !== undefined && clientId !== undefined && (
        <DelProductDialog
          open={openDelProduct}
          close={toggleOpenDelProduct}
          asset={selectAction.getItem()!}
          unselect={selectAction.unselect}
          deleteAsset={removeAsset}
          clientId={clientId}
        />
      )}
    </>
  );
};
