import { TableCellProps } from '@mui/material';
import { IAsset } from 'types/asset';

type IColumn = {
  label: string;
  key: keyof IAsset;
  align: TableCellProps['align'];
  padding: TableCellProps['padding'];
  valueType: 'string' | 'number' | 'Date';
  isDetail: boolean;
  decimalPoints: number | 'cent'; // default to 0
};

export const columnOrder: IColumn[] = [
  {
    label: '銘柄名',
    key: 'productName',
    align: 'left',
    padding: 'normal',
    valueType: 'string',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: '証券口座',
    key: 'brokerageAccount',
    align: 'left',
    padding: 'normal',
    valueType: 'string',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '口座区分',
    key: 'accountType',
    align: 'left',
    padding: 'normal',
    valueType: 'string',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '資産クラス',
    key: 'productType',
    align: 'left',
    padding: 'normal',
    valueType: 'string',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '通貨',
    key: 'currency',
    align: 'left',
    padding: 'normal',
    valueType: 'string',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: '数量',
    key: 'amount',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 'cent',
  },
  {
    label: '単位',
    key: 'unit',
    align: 'left',
    padding: 'normal',
    valueType: 'string',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '買付単価',
    key: 'purchasePrice',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 2,
  },
  {
    label: '時価(評価)',
    key: 'marketPrice',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 2,
  },
  {
    label: '評価額\n現地',
    key: 'marketValueLocal',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '評価額\n円換算',
    key: 'marketValueDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: '評価損益\n現地',
    key: 'PLLocal',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '評価損益\n円換算',
    key: 'PLDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: '実現損益\n円換算',
    key: 'capitalGainDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '含み損益\n円換算',
    key: 'unrealizedGainDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '利金配当金\n現地',
    key: 'distributionLocal',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '利金配当金\n円換算',
    key: 'distributionDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: '利金配当金込み評価損益\n現地',
    key: 'PLInclDistributionLocal',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '利金配当金込み評価損益\n円換算',
    key: 'PLInclDistributionDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: 'TR損益率 (%)\n現地',
    key: 'TRLocal',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: 'TR損益率 (%)\n円換算',
    key: 'TRDomestic',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: false,
    decimalPoints: 0,
  },
  {
    label: '評価日',
    key: 'evaluatedAt',
    align: 'right',
    padding: 'normal',
    valueType: 'Date',
    isDetail: true,
    decimalPoints: 0,
  },
  {
    label: '為替',
    key: 'fxRate',
    align: 'right',
    padding: 'normal',
    valueType: 'number',
    isDetail: true,
    decimalPoints: 3,
  },
];
