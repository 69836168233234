import { ExchangeRateLatest, listExchangeRateLatests } from 'api/exchangeRates';
import { useState, useEffect } from 'react';

export const useExchangeRates = () => {
  const [rates, setRates] = useState<ExchangeRateLatest[]>([]);
  const fetchRates = async () => {
    const _rates = await listExchangeRateLatests();
    setRates(_rates);
  };

  useEffect(() => {
    fetchRates();
  }, []);
  return rates;
};
