import { MentoringInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

interface IMentorCommentInputField {
  mentorComment: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const MentorCommentInputField = ({
  mentorComment,
  onChange,
  disabled = false,
}: IMentorCommentInputField) => {
  return (
    <MentoringInputBox
      icon={<SquareRoundedIcon style={{ color: 'hsl(240, 90%, 80%)' }} />}
      value={mentorComment}
      onChange={onChange}
      hoverBorderColor="hsl(240, 40%, 80%)"
      focusedBorderColor="hsl(240, 65%, 75%)"
      title="メンターからのコメント"
      multiline
      minRows={4}
      disabled={disabled}
    />
  );
};
