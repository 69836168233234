import { commaize } from 'utils';
import { ExchangeRateLatest } from 'api/exchangeRates';
import { Box, Typography } from '@mui/material';

type ExchangeRatesProps = {
  exchangeRates: ExchangeRateLatest[];
};

export const ExchangeRates = ({ exchangeRates }: ExchangeRatesProps) => {
  const text = exchangeRates
    .map(rate => {
      return `${rate.currencyCode}: ${commaize(rate.exchangeRateRd, 3)} (${rate.evaluatedAt.toLocaleDateString()})`;
    })
    .join(', ');
  return (
    <Box sx={{ mt: 8 }}>
      <Typography variant="subtitle2">参考為替レート</Typography>
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
};
