import { TextField } from '@mui/material';
import NumberFormat from 'react-number-format';

export type CommaNumberFieldProps = {
  value: number;
  onChange: (value: number) => void;
};

export const CommaNumberField = ({
  value,
  onChange,
}: CommaNumberFieldProps) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator={true}
      customInput={TextField}
      onValueChange={(values, sourceInfo) => {
        const { value, formattedValue, floatValue } = values;
        onChange(floatValue || 0);
      }}
      suffix="円"
      decimalScale={0}
      style={{ backgroundColor: '#fff' }}
    />
  );
};
