import { useState } from 'react';
import {
  updatePassword,
  AuthError,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { useAuth, usePasswordResetRequest } from 'hooks';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from 'initFirebase';

export const useUpdatePassword = () => {
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const { onUpdate } = usePasswordResetRequest(currentUser?.uid);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorMessage('');
    setNewPassword(event.target.value);
  };

  const _update = async () => {
    const email = currentUser?.email;
    if (!email) {
      return;
    }
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*+_-]).{8,}$/;
    if (!re.test(newPassword)) {
      setErrorMessage(
        '小文字、大文字、記号 (#?!@$%^&*-_) を1文字ずつ以上含み、8文字以上である必要があります。'
      );
      return;
    }
    const credential = await EmailAuthProvider.credential(email, password);

    try {
      await reauthenticateWithCredential(currentUser, credential);
    } catch (e) {
      const errorCode = (e as AuthError).code;
      switch (errorCode) {
        case 'auth/wrong-password':
          setError('パスワードが間違っています。');
          break;
        default:
          setError('認証に関して問題が発生しました。');
          break;
      }
      throw e;
    }

    try {
      await updatePassword(currentUser, newPassword);
      setSuccess(true);
    } catch (e) {
      if ((e as AuthError).code === 'auth/requires-recent-login') {
        setError(
          '最近ログインしてあることが必要です。ログアウト、ログインしてください (現在、再認証機能を開発中です)。'
        );
      }
      throw e;
    }

    return;
  };

  const _updateLastPasswordUpdatedField = async () => {
    if (!currentUser) {
      return;
    }
    const userRef = doc(db, 'clients', currentUser.uid);
    try {
      await updateDoc(userRef, {
        passwordUpdatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const update = async () => {
    setIsLoading(true);
    try {
      await _update();
      await _updateLastPasswordUpdatedField();
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return {
    password,
    newPassword,
    errorMessage,
    success,
    update,
    onPasswordChange,
    onNewPasswordChange,
    error,
    isLoading,
  } as const;
};
