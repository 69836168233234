import { useState } from 'react';

export interface IField {
  value: string;
  errorMessage: string;
  error: boolean;
  setValue: (newValue: string) => void;
  resetValue: () => void;
  resetErrorMessage: () => void;
  setErrorMessage: (message: string) => void;
  setError: (newErrorStatus: boolean) => void;
}

export const useTextField = (initialValue: string) => {
  const [value, _setValue] = useState<string>(initialValue);
  const [errorMessage, _setErrorMessage] = useState<string>('');
  const [error, _setError] = useState<boolean>(false);
  const setValue = (newValue: string) => {
    _setValue(newValue);
    resetErrorMessage();
  };
  const resetErrorMessage = () => {
    _setErrorMessage('');
  };
  const setErrorMessage = (message: string) => {
    _setErrorMessage(message);
  };
  const setError = (newErrorStatus: boolean) => {
    _setError(newErrorStatus);
  };
  const resetValue = () => {
    setValue(initialValue);
  };
  const field: IField = {
    value,
    errorMessage,
    error,
    setValue,
    resetValue,
    resetErrorMessage,
    setErrorMessage,
    setError,
  };
  return field;
};
