import { ClientAssetHistoryAggregationByAssetType, DraftClient, FamilyAssetHistoryAggregationByAssetType, FinancialSummary, IAsset, IManualInput, NewAPIMentoringSheet, NewAPIMentoringSheetStorePayload, NewApiClient } from 'types';


export * from './purchasesAggregatedByProductTypeDaily'

const API_URL = process.env.REACT_APP_PORTFOLIOH_API_URL as string;

export const getTokenWithEmailOrNameAndPassword = async (
  emailOrName: string,
  password: string
) => {
  const payload = emailOrName.includes('@')
    ? { email: emailOrName, password }
    : { name: emailOrName, password };
  const response = await fetch(`${API_URL}/auth/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  throw new Error(await response.json());
};

export const getCustomToken = async (id_token: string) => {
  const response = await fetch(`${API_URL}/auth/custom-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id_token }),
  });
  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  throw new Error(await response.json());
};

export const getAssetsByClientId = async ({
  clientId,
  token,
}: {
  clientId: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()).map((a: any) => ({
    ...a,
    createdAt: a.createdAt ? new Date(a.createdAt)?.getTime() : null,
    updatedAt: a.updatedAt ? new Date(a.updatedAt)?.getTime() : null,
    evaluatedAt: a.evaluatedAt ? new Date(a.evaluatedAt).getTime() : null,
  })) as IAsset[];
};

export const getAssetsByFamilyId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()).map((a: any) => ({
    ...a,
    createdAt: a.createdAt ? new Date(a.createdAt)?.getTime() : null,
    updatedAt: a.updatedAt ? new Date(a.updatedAt)?.getTime() : null,
    evaluatedAt: a.evaluatedAt ? new Date(a.evaluatedAt).getTime() : null,
  }));
};
export const getAssetById = async ({
  clientId,
  id,
  token,
}: {
  clientId: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${clientId}/assets/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const a = await response.json();
  return {
    ...a,
    createdAt: a.createdAt ? new Date(a.createdAt)?.getTime() : null,
    updatedAt: a.updatedAt ? new Date(a.updatedAt)?.getTime() : null,
    evaluatedAt: a.evaluatedAt ? new Date(a.evaluatedAt).getTime() : null,
  };
};

export const postAsset = async ({
  clientId,
  data,
  token,
}: {
  clientId: string;
  data: IManualInput;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const putAsset = async ({
  clientId,
  id,
  data,
  token,
}: {
  clientId: string;
  id: string;
  data: IManualInput;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${clientId}/assets/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  return response.json();
};

export const deleteAsset = async ({
  clientId,
  id,
  token,
}: {
  clientId: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${clientId}/assets/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.body;
};

export const getFamilies = async (token: string) => {
  const response = await fetch(`${API_URL}/families`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json();
};

export const getFamilyById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json();
};

export const postFamily = async ({
  data,
  token,
}: {
  data: { name: string };
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const putFamilyById = async ({
  id,
  data,
  token,
}: {
  id: string;
  data: { name: string };
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const getMembersByRole = async (
  role: string | undefined,
  token: string
) => {
  const roleQuery = role ? `?role=${encodeURIComponent(role)}` : '';
  const response = await fetch(`${API_URL}/members${roleQuery}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export const getClients = async (token: string, familyId?: string) => {
  const response = await fetch(`${API_URL}/clients${familyId ? `?family_id=${familyId}` : ''}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json();
};

export const getClientById = async ({id, token,}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<NewApiClient>;
}

export const putClientById = async ({
  id,
  data,
  token,
}: {
  id: string;
  data: DraftClient;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const getClientFinancialSummary = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}/assets/summary`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<FinancialSummary>;
}

export const getFamilyFinancialSummary = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets/summary`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<FinancialSummary>;
}

export const getClientAssetHistoryAggregationByAssetType = async ({id, token, freq, start_at, end_at}: {id: string, token: string, freq: "daily" | "weekly" | "monthly", start_at: string, end_at: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}/assets/history/aggregation-by-asset-type?start_at=${start_at}&end_at=${end_at}&freq=${freq}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<ClientAssetHistoryAggregationByAssetType[]>;
}

export const getFamilyAssetHistoryAggregationByAssetType = async ({id, token, freq, start_at, end_at}: {id: string, token: string, freq: "daily" | "weekly" | "monthly", start_at: string, end_at: string}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets/history/aggregation-by-asset-type?start_at=${start_at}&end_at=${end_at}&freq=${freq}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<FamilyAssetHistoryAggregationByAssetType[]>;
}

export const getClientMentoringSheets = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}/mentoring-sheets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<NewAPIMentoringSheet[]>;
}

export const getClientMentoringSheet = async ({client_id, mentoring_sheet_id, token}: {client_id: string, mentoring_sheet_id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<NewAPIMentoringSheet>;
}


export const postClientMentoringSheet = async ({client_id, data, token}: {client_id: string, token: string, data: NewAPIMentoringSheetStorePayload}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  return response.json() as Promise<NewAPIMentoringSheet>;
}

export const putClientMentoringSheet = async ({client_id, mentoring_sheet_id, data, token}: {client_id: string, mentoring_sheet_id: string, token: string, data: NewAPIMentoringSheetStorePayload}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
  return response.json() as Promise<NewAPIMentoringSheet>;
}

export const deleteClientMentoringSheet = async ({client_id, mentoring_sheet_id, token}: {client_id: string, mentoring_sheet_id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  return response.json() as Promise<void>;
}