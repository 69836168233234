import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';
import { db } from 'initFirebase';

type PurchasesAggregatedDailyListInput =
  | {
      clientId: string;
      familyId: undefined;
    }
  | {
      clientId: undefined;
      familyId: string;
    };

type AssetSnapshotCommonStore = {
  clientId: string;
  familyId?: string;
  evaluatedAt: Timestamp;
};

export type AssetSnapshotCommon = {
  id: string;
  clientId: string;
  familyId?: string;
  evaluatedAt: Date;
};

export type ProductTypeId =
  | 'bond'
  | 'equity'
  | 'etf'
  | 'investmentTrust'
  | 'fund'
  | 'cache'
  | 'realEstate'
  | 'alternative'
  | 'otherProductType';
export type ProductTypeObj = {
  [key in ProductTypeId]?: number;
};

export type PurchasesAggregatedByProductType = {
  id: string;
  evaluatedAt: Date;
} & ProductTypeObj;

export async function list({
  clientId,
  familyId,
}: {
  clientId?: string;
  familyId?: string;
}): Promise<(AssetSnapshotCommon & ProductTypeObj)[]> {
  if (clientId !== undefined && familyId !== undefined) {
    throw Error('Only one of clientId and familyId should be specified');
  }

  if (clientId !== undefined && familyId === undefined) {
    const q = query(
      collection(db, 'purchasesAggregatedByProductTypeDaily'),
      where('clientId', '==', clientId)
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .filter(filterData)
      .map(postprocess)
      .sort((a, z) => a.evaluatedAt.getTime() - z.evaluatedAt.getTime());
    return data;
  } else if (clientId === undefined && familyId !== undefined) {
    const q = query(
      collection(db, 'purchasesAggregatedByProductTypeDaily'),
      where('familyId', '==', familyId)
    );
    try {
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs
        .filter(filterData)
        .map(postprocessFamily)
        .sort((a, z) => a.evaluatedAt.getTime() - z.evaluatedAt.getTime());
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  } else {
    throw Error('At least one of clientId or familyId should be specified');
  }
}

const filterData = (doc: QueryDocumentSnapshot<DocumentData>) => {
  return doc.data().hasOwnProperty('evaluatedAt');
};
const postprocess = (doc: QueryDocumentSnapshot<DocumentData>) => {
  const storeData = { id: doc.id, ...doc.data() } as AssetSnapshotCommonStore &
    ProductTypeObj & { id: string };
  if (!storeData.evaluatedAt.toDate()) {
    throw new Error();
  }
  const datum: AssetSnapshotCommon & ProductTypeObj = {
    ...storeData,
    evaluatedAt: storeData.evaluatedAt.toDate(),
  };

  return datum;
};

const postprocessFamily = (doc: QueryDocumentSnapshot<DocumentData>) => {
  const storeData = doc.data() as Required<AssetSnapshotCommonStore> &
    ProductTypeObj;
  const datum: Required<AssetSnapshotCommon> & ProductTypeObj = {
    id: doc.id,
    ...storeData,
    evaluatedAt: storeData.evaluatedAt.toDate(),
  };
  return datum;
};
