import { Button, Grid } from '@mui/material';
import { NetWorthInputField } from './NetWorthInputField';
import { FinancialGoalInputField } from './FinancialGoalInputField';
import { MentorCommentInputField } from './MentorCommentInputField';
import { NextActionsInputField } from './NextActionsInputField';
import { ResultInputField } from './ResultInputField';
import { ReviewInputField } from './ReviewInputField';
import { TODOInputField } from './TODOInputField';
import { MentoringSheet } from 'types';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import { MtgMemoHeader } from './MtgMemoHeader';

type MentoringSheetFormProps = {
  sheet: MentoringSheet;
  // buildSetter: (key: keyof IMentoringSheet) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  edit: (fracSheet: Partial<MentoringSheet>) => void;
  // onChangeNetWorthGoal: (goal: number) => void,
  save: () => void;
  disableMentorComment?: boolean;
};

export const MentoringSheetForm = ({
  sheet,
  edit,
  save,
  disableMentorComment = false,
}: MentoringSheetFormProps) => {
  const mtgMemoLexical =
    sheet.mtgMemoLexical &&
      JSON.parse(sheet.mtgMemoLexical).root.children[0].children.length > 0
      ? sheet.mtgMemoLexical
      : JSON.stringify({
        root: {
          children: [
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: sheet.mtgMemo ?? '',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: 'null',
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
          ],
          direction: 'null',
          format: '',
          indent: 0,
          type: 'root',
          version: 1,
        },
      });
  return (
    <Grid item container spacing={2} sx={{ px: 2, py: 5 }}>
      <Grid item md={4}>
        <NetWorthInputField
          assetGoal={sheet.netWorthGoal}
          onChange={n => edit({ netWorthGoal: n })}
        />
      </Grid>
      <Grid item xs={12}>
        <FinancialGoalInputField
          goal={sheet.financialGoal}
          onChange={e => edit({ financialGoal: e.target.value })}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TODOInputField
          todo={sheet.todo}
          onChange={e => edit({ todo: e.target.value })}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ResultInputField
          result={sheet.result}
          onChange={e => edit({ result: e.target.value })}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ReviewInputField
          review={sheet.review}
          onChange={e => edit({ review: e.target.value })}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <NextActionsInputField
          nextActions={sheet.nextActions}
          onChange={e => edit({ nextActions: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <MentorCommentInputField
          mentorComment={sheet.mentorComment}
          disabled={disableMentorComment}
          onChange={e => edit({ mentorComment: e.target.value })}
        />
      </Grid>
      {/* <Grid item xs={12}>
      <MtgMemoInputField
        mtgMemo={sheet.mtgMemo}
        onChange={(e) => edit({ mtgMemo: e.target.value })}
      />
    </Grid> */}
      <Grid item xs={12}>
        <MtgMemoHeader />
        <RichTextEditor
          mtgMemoLexical={mtgMemoLexical}
          onChange={(editorStateJSON: string) => {
            edit({ mtgMemoLexical: editorStateJSON });
          }}
          key={sheet.id}
        />
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={save}
        >
          変更を保存する
        </Button>
      </Grid>
    </Grid>
  );
};
