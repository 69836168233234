import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { IClient } from 'types/client';

type DeleteClientDialogProps = {
  open: boolean;
  onClose: () => void;
  client: {
    id: string;
    name: string;
  };
};
export const DeleteClientDialog = ({
  open,
  onClose,
  client,
}: DeleteClientDialogProps) => (
  <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>顧客削除</DialogTitle>
    <DialogContent sx={{ mx: 4 }}>
      <DialogContentText>{`本当に「${client.name}」様を削除しますか？`}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'flex-start', mx: 6, mb: 4 }}>
      <Button variant="outlined" onClick={onClose}>
        キャンセル
      </Button>
      <Button
        variant="contained"
        color="warning"
        disabled
        // disabled={name.length === 0 || email.length === 0 || password.length < 6}
        onClick={() => {}}
      >
        削除機能はまもなく追加予定です
      </Button>
    </DialogActions>
  </Dialog>
);
