import React, { useEffect } from 'react';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';

export const useRequireAuth = (redirectUrl = '/signin') => {
  // TODO: Redirect to /admin if admin
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === null) {
      navigate(redirectUrl, { replace: true });
    }
  }, [currentUser, navigate]);
};
