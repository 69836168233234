import { useState } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { ClientForClientsPage, DraftClient } from 'types/client';
import { Family } from 'types/family';
import { Member } from 'types/member';

interface EditClientDialogProps {
  open: boolean;
  onClose: () => void;
  client: ClientForClientsPage;
  families: Family[];
  mentors: Member[];
  onSave: (formState: DraftClient) => Promise<void>;
  status: 'loading' | 'idle' | 'succeeded' | 'failed';
  error: string | null;
}
export const EditClientDialog = ({
  open,
  onClose,
  client,
  families,
  mentors,
  onSave,
  status,
  error,
}: EditClientDialogProps) => {
  const [formState, setFormState] = useState<DraftClient>({
    name: client.name,
    fullName: client.fullName ?? undefined,
    status: client.status,
    clientBillingId: client.clientBillingId,
    mentoredBy: client.mentoredBy,
    familyId: client.familyId,
    note: client.note,
  });
  const handleSave = async () => {
    try {
      await onSave(formState);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>顧客編集</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <div>
          <TextField
            type="text"
            label="P王番号"
            value={formState.name}
            autoFocus
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({ ...prev, name: event.target.value }));
            }}
          />
        </div>
        <div>
          <FormControl variant="standard" sx={{ my: 2, width: '200px' }}>
            <InputLabel id="client-family-select-label">ファミリー</InputLabel>
            <Select
              labelId="client-family-select-label"
              value={formState.familyId}
              label="ファミリー"
              onChange={event => {
                setFormState(prev => {
                  const newState = { ...prev };
                  if (event.target.value === '') {
                    delete newState.familyId;
                  } else {
                    newState.familyId = event.target.value;
                  }
                  return newState;
                });
              }}
            >
              <MenuItem value="">選択なし</MenuItem>
              {families.map(family => (
                <MenuItem key={family.id} value={family.id}>
                  {family.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="standard" sx={{ my: 2, width: '200px' }}>
            <InputLabel id="client-mentoredBy-select-label">
              メンター
            </InputLabel>
            <Select
              labelId="client-mentoredBy-select-label"
              value={formState.mentoredBy}
              label="メンター"
              onChange={event => {
                setFormState(prev => {
                  const newState = { ...prev };
                  if (event.target.value === '') {
                    delete newState.mentoredBy;
                  } else {
                    newState.mentoredBy = event.target.value;
                  }
                  return newState;
                });
              }}
            >
              <MenuItem value="">選択なし</MenuItem>
              {mentors.map(mentor => (
                <MenuItem key={mentor.id} value={mentor.id}>
                  {mentor.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            type="text"
            label="メモ"
            autoComplete="off"
            value={formState.note}
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({ ...prev, note: event.target.value }));
            }}
            fullWidth
            rows={2}
          />
        </div>
        <div>
          <FormControl variant="standard" sx={{ my: 2, width: '160px' }}>
            <InputLabel id="client-status-select-label">ステータス</InputLabel>
            <Select
              labelId="client-status-select-label"
              value={formState.status}
              label="ステータス"
              onChange={event => {
                setFormState(prev => ({
                  ...prev,
                  status: event.target.value,
                }));
              }}
            >
              {['顧客', 'リード', 'デモ', '解約済み'].map(status => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            type="text"
            label="請求先番号"
            autoComplete="off"
            value={formState.clientBillingId}
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({
                ...prev,
                clientBillingId: event.target.value,
              }));
            }}
            fullWidth
            rows={2}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', mx: 6, mb: 4 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSave}>
          {status === 'loading' ? '更新中...' : '編集する'}
        </Button>
      </DialogActions>
      {status === 'failed' && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          編集できませんでした
        </div>
      )}
    </Dialog>
  );
};
