import { useEffect, useState, useCallback } from 'react';
import { useFirestoreDocument, useToggle } from 'hooks';
import { doc } from 'firebase/firestore';
import { db } from 'initFirebase';
import { financialProductConverter } from 'interactions/financialProductConverter';

export const useFinancialProductDetailDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [bbgDocId, _setBbgDocId] = useState<string | null>(null);
  const docRef =
    bbgDocId !== null && bbgDocId.length > 0
      ? doc(db, 'bloomberg', bbgDocId).withConverter(financialProductConverter)
      : undefined;
  const { data: product, isLoading, error } = useFirestoreDocument(docRef);
  useEffect(() => {
    if (product !== undefined && !isLoading) {
      setOpen(true);
    }
  }, [product, isLoading]);
  const close = useCallback(() => {
    _setBbgDocId(null);
    setOpen(false);
  }, []);
  const setBbgDocId = (id: string) => {
    _setBbgDocId(id);
  };
  return [open, product, isLoading, error, { setBbgDocId, close }] as const;
};
