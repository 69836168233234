import React from 'react';
import { Loading, ReportPresentation } from 'components';
import {
  useRequireAuth,
  useExchangeRates,
} from 'hooks';
import { useStore } from 'contexts';

export const FamilyReportPage = () => {
  useRequireAuth();
  const { familyInfo } = useStore()
  const exchangeRates = useExchangeRates();

  if (familyInfo.status === 'idle' || familyInfo.status === 'loading') {
    return <Loading />;
  }
  return (
    <ReportPresentation
      clientOrFamily={familyInfo.data?.family!}
      assets={familyInfo.data?.assets ?? []}
      barData={familyInfo.data?.historicalAggregationByAssetTypeMonthly ?? []}
      exchangeRates={exchangeRates}
    />
  );
};
