import React from 'react';

import {
  Box,
  TextField,
  Button,
  CircularProgress,
  TextFieldProps,
} from '@mui/material';

interface IAuthTextField {
  label?: string;
  value: string;
  handleChange: (value: string) => void;
  errorMessage: string;
}
export const AuthTextField = (props: IAuthTextField & TextFieldProps) => {
  const { label, value, handleChange, errorMessage, type } = props;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };
  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        {...props}
        onChange={onChange}
        variant="outlined"
        value={value}
        size="small"
        placeholder={label}
        fullWidth
        helperText={errorMessage}
        error={errorMessage.length > 0}
        type={type}
      />
    </Box>
  );
};

interface IAuthButton {
  handleClick: () => void;
}

export const AuthButton: React.FC<IAuthButton> = ({
  children,
  handleClick,
}) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    handleClick();
  };
  return (
    <Button
      onClick={onClick}
      color="secondary"
      variant="contained"
      size="large"
      type="submit"
      sx={{ mt: 3 }}
    >
      {children}
    </Button>
  );
};

interface IAuthForm {
  title: string;
  buttonLabel: string;
  isLoading: boolean;
  emailErrorMessage: string;
  passwordErrorMessage: string;
  onClick: (email: string, password: string) => void;
  email: string;
  password: string;
  handleEmailChange: (newEmail: string) => void;
  handlePasswordChange: (newPassword: string) => void;
  autoComplete: string;
}

export const AuthForm = ({
  title,
  buttonLabel,
  isLoading,
  emailErrorMessage,
  passwordErrorMessage,
  onClick,
  email,
  password,
  handleEmailChange,
  handlePasswordChange,
  autoComplete,
}: IAuthForm) => {
  const handleClick = () => {
    onClick(email, password);
  };

  return (
    <>
      <Box sx={{ textAlign: 'center', mt: 1, mb: 5 }}>{title}</Box>
      <AuthTextField
        label="メールアドレス"
        value={email}
        handleChange={handleEmailChange}
        errorMessage={emailErrorMessage}
        type="text"
      />
      <AuthTextField
        label="パスワード"
        value={password}
        handleChange={handlePasswordChange}
        errorMessage={passwordErrorMessage}
        type="password"
        autoComplete={autoComplete}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: '#fff' }} />
          ) : (
            buttonLabel
          )}
        </Button>
      </Box>
    </>
  );
};
