import { IAsset, IChartData } from 'types/asset';

export const calcGroupBy = (assets: IAsset[] | undefined) => {
  const currency =
    assets
      ?.reduce<IChartData[]>((a, c) => {
        const currency = c.currency;
        const marketValueDomestic = c.marketValueDomestic;

        if (marketValueDomestic === undefined || marketValueDomestic === null) {
          return a;
        }

        for (const row of a) {
          if (row.label === currency) {
            row.value += marketValueDomestic;
            return a;
          }
        }

        a.push({
          label: currency,
          value: marketValueDomestic,
        });
        return a;
      }, [])
      .sort((a, b) => b.value - a.value) || [];

  const productType =
    assets
      ?.reduce<IChartData[]>((a, c) => {
        const productType = c.productType;
        const marketValueDomestic = c.marketValueDomestic;

        if (marketValueDomestic === undefined || marketValueDomestic === null) {
          return a;
        }

        for (const row of a) {
          if (row.label === productType) {
            row.value += marketValueDomestic;
            return a;
          }
        }

        a.push({
          label: productType,
          value: marketValueDomestic,
        });
        return a;
      }, [])
      .sort((a, b) => b.value - a.value) || [];

  const brokerageAccount =
    assets
      ?.reduce<IChartData[]>((a, c) => {
        const brokerageAccount = c.brokerageAccount;
        const marketValueDomestic = c.marketValueDomestic;

        if (marketValueDomestic === undefined || marketValueDomestic === null) {
          return a;
        }

        for (const row of a) {
          if (row.label === brokerageAccount) {
            row.value += marketValueDomestic;
            return a;
          }
        }

        a.push({
          label: brokerageAccount,
          value: marketValueDomestic,
        });
        return a;
      }, [])
      .sort((a, b) => b.value - a.value) || [];

  const groupBy = {
    currency,
    productType,
    brokerageAccount,
  };
  return groupBy;
};
