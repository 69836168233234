import React, { useState, createContext } from 'react';
import {
  ErrorSnackbar,
  InfoSnackbar,
  SuccessSnackbar,
  WarningSnackbar,
} from './Snackbars';

interface ISnackbarContext {
  showSuccessSnackbar: (message: string) => void;
  showInfoSnackbar: (message: string) => void;
  showWarningSnackbar: (message: string) => void;
  showErrorSnackbar: (message: string) => void;
}

export const SnackbarContext = createContext<ISnackbarContext>(
  undefined as any
);

const useSnackbarProvider = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const handleOpen = (message: string) => {
    setMessage(message);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return { open, onClose, handleOpen, message } as const;
};

export const SnackbarProvider: React.FC = ({ children }) => {
  // const [open, toggle] = useToggle(false)
  const { handleOpen: showSuccessSnackbar, ...successProps } =
    useSnackbarProvider();
  const { handleOpen: showWarningSnackbar, ...warningProps } =
    useSnackbarProvider();
  const { handleOpen: showInfoSnackbar, ...infoProps } = useSnackbarProvider();
  const { handleOpen: showErrorSnackbar, ...errorProps } =
    useSnackbarProvider();
  const snackbarController = {
    showSuccessSnackbar,
    showInfoSnackbar,
    showWarningSnackbar,
    showErrorSnackbar,
  };

  return (
    <SnackbarContext.Provider value={snackbarController}>
      {children}
      <SuccessSnackbar {...successProps} />
      <InfoSnackbar {...infoProps} />
      <WarningSnackbar {...warningProps} />
      <ErrorSnackbar {...errorProps} />
    </SnackbarContext.Provider>
  );
};
