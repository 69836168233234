import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { IAsset } from 'types/asset';
import { SignAwareTableCell } from './SignAwareTableCell';
import { getCurrencySymbolFromCode, commaize, nullcommaize } from 'utils';
import { AssetDetailDialog } from './AssetDetailDialog';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface AssetTableRowProps {
  asset: IAsset;
  checkboxSelection: boolean;
  checked?: boolean;
  selectedAssets?: IAsset[];
  onSelectAsset?: (asset: IAsset) => void;
  onClickProductName?: (bbgDocId: string) => void;
  detailVisible: boolean;
}

export const AssetTableRow = ({
  asset,
  checkboxSelection,
  checked,
  onSelectAsset,
}: AssetTableRowProps) => {
  const currency = asset['currency'] as Parameters<
    typeof getCurrencySymbolFromCode
  >[0];
  const currencySymbol =
    asset['purchasePrice'] !== undefined
      ? getCurrencySymbolFromCode(currency) ?? ''
      : '';
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TableRow tabIndex={-1}>
        <TableCell
          sx={{
            padding: 1,
            position: 'sticky',
            left: 0,
            whiteSpace: 'unset',
            backgroundColor: '#fafafa',
            fontSize: 14,
          }}
          rowSpan={2}
        >
          {asset['productName']}
          <IconButton sx={{ padding: 0, paddingLeft: 0.5 }}
            onClick={handleClickOpen}
          >
            <ZoomInIcon
              color="primary"
              fontSize="small"
            />
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            px: 1,
            py: 0,
            fontSize: 14,
          }}
          padding="normal"
          align="right"
        >
          {asset['purchasePrice'] !== null &&
            asset['purchasePrice'] !== undefined
            ? currencySymbol + ' ' + commaize(asset['purchasePrice'], 2)
            : '-'}
        </TableCell>
        <SignAwareTableCell
          key="marketValueDomestic"
          value={asset['marketValueDomestic']}
          padding="normal"
          align="right"
          decimalPoints={0}
        />
      </TableRow>
      <TableRow>
        <TableCell
          key="marketPrice"
          sx={{
            px: 1,
            py: 0,
            fontSize: 14,
          }}
          padding="normal"
          align="right"
        >
          {asset['marketPrice'] !== null && asset['marketPrice'] !== undefined
            ? currencySymbol + ' ' + commaize(asset['marketPrice'], 2)
            : '-'}
        </TableCell>
        <SignAwareTableCell
          key="PLDomestic"
          value={asset['PLDomestic']}
          padding="normal"
          align="right"
          decimalPoints={0}
        />
      </TableRow>
      <AssetDetailDialog open={open} asset={asset} close={handleClose} />
    </>
  );
};
