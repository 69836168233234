import { httpsCallable } from 'firebase/functions';
import {
  Query,
  getDoc,
  getDocs,
  Timestamp,
  query,
  where,
} from 'firebase/firestore';
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db, functions } from 'initFirebase';
import { clientConverter } from 'hooks/useClient';

const cachedClients: Record<
  string,
  {
    name: string;
    familyName?: string;
    familyId?: string;
    id: string;
    email?: string;
    manuallyUpdatedAt?: Date;
    note?: string;
    latestMentoringSheetId?: string;
    passwordUpdatedAt?: Date;
  }
> = {};

type CreateClientInput = {
  email: string;
  name: string;
  password: string;
  familyId: string | undefined;
  familyName: string | undefined;
  status: string;
  clientBillingId: string;
};

type CreateClientResult = {
  success: boolean;
};

const createNewAccount = httpsCallable<CreateClientInput, CreateClientResult>(
  functions,
  'createNewAccount'
);

export const getClient = async (
  id: string
): Promise<{
  name: string;
  familyName?: string;
  familyId?: string;
  id: string;
  email?: string;
  manuallyUpdatedAt?: Date;
  note?: string;
  latestMentoringSheetId?: string;
  passwordUpdatedAt?: Date;
}> => {
  if (cachedClients[id]) {
    return cachedClients[id];
  }
  const docRef = doc(db, 'clients', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    throw new Error(`clients/${id} does not exist`);
  }
  const data = docSnap.data();

  const client = {
    id: docSnap.id,
    name: data?.name,
    note: data?.note,
    email: data?.email,
    latestMentoringSheetId: data?.latestMentoringSheetId,
    passwordUpdatedAt: (
      data?.passwordUpdatedAt as Timestamp | undefined
    )?.toDate?.(),
    familyId: data?.familyId,
    familyName: data?.familyName,
    manuallyUpdatedAt: data?.manuallyUpdatedAt,
  };
  cachedClients[id] = client;
  return client;
};

export const addClient = async (client: {
  name: string;
  email: string;
  password: string; // for new client
  familyName?: string;
  familyId?: string;
  status: string;
  clientBillingId: string;
}) => {
  try {
    const { data } = await createNewAccount({
      email: client.email,
      password: client.password!,
      name: client.name,
      familyId: client.familyId,
      familyName: client.familyName,
      status: client.status,
      clientBillingId: client.clientBillingId,
    });
    if (!data.success) {
      throw new Error('クライアントの追加に失敗しました。');
    }
    return data;
  } catch (e) {
    throw new Error('クライアントの追加に失敗しました。');
  }
};

export const updateClient = async (client: {
  id: string;
  name: string;
  familyName?: string;
  familyId?: string;
  note?: string;
  status: string;
  clientBillingId: string;
}) => {
  try {
    const newClient = await (async () => {
      if (client.familyName) {
        if (client.familyId) {
          // belong to an existing family
          return client;
        } else {
          // belong to a new family
          const familyDocRef = await addDoc(collection(db, 'families'), {
            name: client.familyName,
          });
          return {
            client,
            familyId: familyDocRef.id,
          };
        }
      } else {
        // not belong to a family
        return {
          ...client,
          familyId: deleteField(),
          familyName: deleteField(),
        };
      }
    })();
    delete cachedClients[client.id];
    return await updateDoc(
      doc(db, 'clients', client.id).withConverter(clientConverter),
      client
    );
  } catch (e) {
    throw e;
  }
};

export const deleteClient = async (client: { id: string }) => {
  return await deleteDoc(doc(db, 'clients', client.id));
  // delete auth account
};

export const doesClientExist = async (clientId: string) => {
  try {
    await getClient(clientId);
    return true;
  } catch (e) {
    return false;
  }
};

export const listClientsByFamilyId = async (familyId: string) => {
  const q: Query = query(
    collection(db, 'clients'),
    where('familyId', '==', familyId)
  );
  const clientsQuerySnapshot = await getDocs(q);
  const clients = clientsQuerySnapshot.docs.map(docSnap => {
    const data = docSnap.data() as {
      name: string;
      email: string;
      password: string; // for new client
      familyName?: string;
      familyId?: string;
    };
    const client = { id: docSnap.id, ...data };
    return client;
  });
  return clients;
};
