import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { IAsset } from 'types/asset';

// 銘柄削除モーダル
type DelProductModalProps = {
  open: boolean;
  close: () => void;
  asset: IAsset;
  unselect: () => void;
  deleteAsset: (payload: { clientId: string; id: string }) => void;
  clientId: string;
};

export function DelProductDialog({
  open,
  close,
  asset,
  unselect,
  deleteAsset,
  clientId,
}: DelProductModalProps) {
  return (
    <Dialog open={open}>
      {/* <div style={modalStyle} className={classes.root}> */}
      <DialogTitle>銘柄削除</DialogTitle>
      <DialogContent>
        <DialogContentText>
          本当に{asset.productName}を削除しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            try {
              const docId = asset.id;
              if (docId === undefined) {
                throw `${asset.toString()} 's docId is undefined`;
              }
              deleteAsset({ clientId, id: docId });
              unselect();
              close();
            } catch (e) {
              throw e;
            }
          }}
        >
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
}
