import { Box } from '@mui/material';
interface InsightSlideProps {
  speakerDeckIFrame: string;
}

export const InsightSlide = ({ speakerDeckIFrame }: InsightSlideProps) => {
  const speakerDeckIFrameAddedStyle = speakerDeckIFrame.replace(
    /<iframe/,
    `<iframe style="position:absolute;top:0;left:0;width:100%;height:100%;"`
  );
  return (
    <Box
      sx={{
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        width: '100%',
        '& iframe': {
          position: 'absolute',
          border: 0,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        },
      }}
      dangerouslySetInnerHTML={{
        __html: speakerDeckIFrameAddedStyle,
      }}
    />
  );
};
