import React, { useState } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface UserTabsProps extends TabsProps {
  initialTabValue?: string;
}

export const UserTabs = ({ initialTabValue, ...props }: UserTabsProps) => {
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    navigate(newValue);
  };
  const { clientId, familyId } = useParams();
  const stemPath = clientId ? '/clients/' + clientId : '/families/' + familyId;
  const mentoringPath = stemPath + '/mentoring';
  const reportPath = stemPath + '/report';
  const iconStyle = {
    fontSize: 'body1.fontSize',
    verticalAlign: 'bottom',
    mr: '3px',
  };
  const tabStyleForMobile = { fontSize: 11, mx: 0.5, minWidth: 0, p: 0 };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMobile ? (
        <Tabs
          value={initialTabValue}
          onChange={handleChange}
          {...props}
          variant="fullWidth"
        >
          <Tab
            icon={<DashboardIcon />}
            label="ダッシュボード"
            value={stemPath}
            sx={tabStyleForMobile}
          />
          <Tab
            icon={<EditIcon />}
            label="G-POP&reg;"
            value={mentoringPath}
            sx={tabStyleForMobile}
          />
          <Tab
            icon={<ShowChartIcon />}
            label="レポート"
            value={reportPath}
            sx={tabStyleForMobile}
          />
        </Tabs>
      ) : (
        <Tabs value={initialTabValue} onChange={handleChange} {...props}>
          <Tab
            label={
              <div>
                <DashboardIcon sx={iconStyle} />
                ダッシュボード
              </div>
            }
            value={stemPath}
          />
          <Tab
            label={
              <div>
                <EditIcon sx={iconStyle} />
                G-POP&reg;
              </div>
            }
            value={mentoringPath}
          />
          <Tab
            label={
              <div>
                <ShowChartIcon sx={iconStyle} />
                レポート
              </div>
            }
            value={reportPath}
          />
        </Tabs>
      )}
    </>
  );
};
