import { Tooltip, IconButton, Paper, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

interface ClientsTitleProps {
  downloadExcel: (orderBy?: string, order?: 'asc' | 'desc') => void;
  toggleAddClientDialog: () => void;
  toggleEditClientDialog: () => void;
  toggleDeleteClientDialog: () => void;
  isClientSelected: boolean;
  downloadExcelStatus: 'idle' | 'loading' | 'success' | 'error';
  downloadExcelErrorMessage: string | null;
  clientSearchQuery: string;
  onChangeClientSearchQuery: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const ClientsMenu = ({
  downloadExcel,
  toggleAddClientDialog,
  toggleEditClientDialog,
  toggleDeleteClientDialog,
  isClientSelected,
  downloadExcelStatus,
  downloadExcelErrorMessage,
  clientSearchQuery,
  onChangeClientSearchQuery,
}: ClientsTitleProps) => (
  <ClientsMenuBox elevation={0}>
    <Tooltip title="顧客を追加">
      <IconButton aria-label="add" onClick={toggleAddClientDialog}>
        <AddCircleIcon color="primary" />
      </IconButton>
    </Tooltip>
    {isClientSelected && (
      <Tooltip title="顧客を編集">
        <IconButton aria-label="edit" onClick={toggleEditClientDialog}>
          <EditIcon color="primary" />
        </IconButton>
      </Tooltip>
    )}
    {isClientSelected && (
      <Tooltip title="顧客を削除">
        <IconButton aria-label="delete" onClick={toggleDeleteClientDialog}>
          <DeleteIcon color="primary" />
        </IconButton>
      </Tooltip>
    )}
    <Tooltip title="全顧客のポートフォリオをExcelファイルでダウンロード (売却済み銘柄も含まれます)">
      {downloadExcelStatus === 'loading' ? (
        <CircularProgress size="1.685rem" />
      ) : (
        <IconButton aria-label="download" onClick={() => downloadExcel()}>
          <GetAppIcon color="primary" />
        </IconButton>
      )}
    </Tooltip>
    {downloadExcelStatus === 'error' && downloadExcelErrorMessage !== null ? (
      <div>{downloadExcelErrorMessage}</div>
    ) : null}
    <TextField
      label="顧客を検索（ポートフォリ王番号、ファミリー、メンター、請求先番号、メモで検索できます）"
      variant="outlined"
      fullWidth
      value={clientSearchQuery}
      onChange={onChangeClientSearchQuery}
      size="small"
    />
  </ClientsMenuBox>
);

const ClientsMenuBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  zIndex: theme.zIndex.appBar, // same as app bar
  position: 'sticky',
  top: 0,
  pt: 1,
}));
