import React from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import StarsIcon from '@mui/icons-material/Stars';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface AdminClientsTabsProps extends TabsProps {
  clientId: string;
  currentPath: string;
}

export const AdminClientsTabs = React.memo(
  ({ clientId, currentPath, ...props }: AdminClientsTabsProps) => {
    const navigate = useNavigate();
    const onChange = (event: React.SyntheticEvent, value: string) => {
      navigate(value);
    };

    const iconStyle = {
      fontSize: 'body1.fontSize',
      verticalAlign: 'bottom',
      mr: '3px',
    };

    const tabData = [
      {
        path: `/admin/clients/${clientId}`,
        name: 'ダッシュボード',
        icon: <DashboardIcon sx={iconStyle} />,
      },
      {
        path: `/admin/clients/${clientId}/mentoring`,
        name: 'G-POP\u{00AE}',
        icon: <EditRoundedIcon sx={iconStyle} />,
      },
      {
        path: `/admin/clients/${clientId}/insight`,
        name: '戦略くん',
        icon: <StarsIcon sx={iconStyle} />,
      },
      {
        path: `/admin/clients/${clientId}/assets`,
        name: '銘柄一覧',
        icon: <ListRoundedIcon sx={iconStyle} />,
      },
      {
        path: `/admin/clients/${clientId}/report`,
        name: 'レポート',
        icon: <ShowChartRoundedIcon sx={iconStyle} />,
      },
    ];
    // TODO: Remove trailing slash from URL
    const tabStyleForMobile = { fontSize: 11, mx: 0.5, minWidth: 0, p: 0 };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <>
        {isMobile ? (
          <Tabs
            value={currentPath}
            onChange={onChange}
            {...props}
            variant="fullWidth"
          >
            {tabData.map(tab => (
              <Tab
                key={tab.path}
                icon={tab.icon}
                value={tab.path}
                sx={tabStyleForMobile}
              />
            ))}
          </Tabs>
        ) : (
          <Tabs value={currentPath} onChange={onChange} {...props}>
            {tabData.map(tab => (
              <Tab
                key={tab.path}
                label={
                  <div>
                    {tab.icon}
                    {tab.name}
                  </div>
                }
                value={tab.path}
              />
            ))}
          </Tabs>
        )}
      </>
    );
  }
);
