import styled from '@emotion/styled';
import { TextField, Box, TextFieldProps, Typography } from '@mui/material';
import {
  CommaNumberField,
  CommaNumberFieldProps,
} from 'components/CommaNumberField';

interface IBorder {
  hoverBorderColor: string;
  focusedBorderColor: string;
}

const generateCustomBorder = ({
  hoverBorderColor,
  focusedBorderColor,
}: IBorder) => ({
  '& .TextField-without-border-radius fieldset': {
    borderRadius: '0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '6px',
      borderColor: '#ddd',
      borderWidth: '2px',
    },
    '&:hover fieldset': {
      borderColor: hoverBorderColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: focusedBorderColor,
    },
    backgroundColor: '#fff',
  },
});
const CustomBorderTextField = styled(TextField)<TextFieldProps & IBorder>(
  generateCustomBorder
);

const CustomBorderCommaNumberField = styled(CommaNumberField)<
  CommaNumberFieldProps & IBorder
>(generateCustomBorder);

interface IMentoringInputBox {
  icon: JSX.Element;
  title: string;
  hoverBorderColor: string;
  focusedBorderColor: string;
}

export const MentoringInputBox = (
  props: IMentoringInputBox & TextFieldProps
) => {
  const {
    icon,
    title,
    hoverBorderColor,
    focusedBorderColor,
    ...textFieldProps
  } = props;
  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ mb: 0.2 }}>
        {icon}
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#0b4862',
            ml: 0.5,
          }}
        >
          {title}
        </Typography>
      </Box>
      <CustomBorderTextField
        {...textFieldProps}
        hoverBorderColor={hoverBorderColor}
        focusedBorderColor={focusedBorderColor}
        fullWidth
      />
    </Box>
  );
};

export const MentoringNetWorthGoalInputBox = (
  props: IMentoringInputBox & CommaNumberFieldProps
) => {
  const {
    icon,
    title,
    hoverBorderColor,
    focusedBorderColor,
    ...textFieldProps
  } = props;
  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ mb: 0.2 }}>
        {icon}
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#0b4862',
            ml: 0.5,
          }}
        >
          {title}
        </Typography>
      </Box>
      <CustomBorderCommaNumberField
        {...textFieldProps}
        hoverBorderColor={hoverBorderColor}
        focusedBorderColor={focusedBorderColor}
      />
    </Box>
  );
};
