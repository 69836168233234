import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Cell, Legend, PieChart, Pie, ResponsiveContainer } from 'recharts';
import { IChartData } from 'types/asset';

export interface PieChartDataUnit {
  name: string;
  value: number;
}

interface AssetPieChartProps {
  data: IChartData[];
  label: string;
  hueOffset?: number;
}

export const AssetPieChart = ({
  data,
  label,
  hueOffset = 0,
}: AssetPieChartProps) => {
  const renderBlackLegendText = (val: string) => {
    return <span style={{ color: '#000' }}>{val}</span>;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: 300 }}>
        <Typography variant="subtitle2">{label}</Typography>
        {data.length === 0 && <Typography>データがありません</Typography>}
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie
              data={data}
              cx={120}
              cy={120}
              labelLine={false}
              label={renderLabel}
              outerRadius={90}
              fill="#8884d8"
              nameKey="label"
              dataKey="value"
              isAnimationActive={false}
              startAngle={90 + 360}
              endAngle={90}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`hsl(${index * 40 + hueOffset}, 90%, 85%)`}
                />
              ))}
            </Pie>
            {data.length > 0 && (
              <Legend
                verticalAlign="bottom"
                align="left"
                formatter={renderBlackLegendText}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

const renderLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (props.percent < 0.06) {
    return null;
  }
  const percentage = (props.percent * 100).toFixed(0);
  return (
    <text
      x={x}
      y={y}
      fill="#444"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {percentage}%
    </text>
  );
};
