import { AuthRootWrapper } from 'components/AuthRootWrapper';
import { MaintenanceInfo } from 'components/MaintenanceInfo';
import { useAuth, useRedirectIfAdmin } from 'hooks';
import bgImg from 'assets/loginbg2.png';
import { LinkToForgotPassword } from 'components/LinkToForgotPassword';
import { LinKToHelp } from 'components/LinkToHelp';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useState } from 'react';

interface IAuthTextField {
  label?: string;
  value: string;
  handleChange: (value: string) => void;
  errorMessage: string;
}
const AuthTextField = (props: IAuthTextField & TextFieldProps) => {
  const { label, value, handleChange, errorMessage, type } = props;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };
  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        {...props}
        onChange={onChange}
        variant="outlined"
        value={value}
        size="small"
        placeholder={label}
        fullWidth
        helperText={errorMessage}
        error={errorMessage.length > 0}
        type={type}
      />
    </Box>
  );
};

export const AdminSignInPage = () => {
  const { signIn, status, error } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useRedirectIfAdmin();
  return (
    <AuthRootWrapper bgImg={bgImg}>
      <Box
        sx={{ textAlign: 'center', mt: 1, mb: 5 }}
        dangerouslySetInnerHTML={{ __html: 'ポートフォリ王 &reg;' }}
      />
      <Box sx={{ mb: 2 }}>
        <TextField
          onChange={event => setEmail(event.target.value)}
          variant="outlined"
          value={email}
          size="small"
          label="メールアドレス"
          fullWidth
          type="text"
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
          variant="outlined"
          value={password}
          size="small"
          label="パスワード"
          fullWidth
          helperText={error !== null ? error : ''}
          error={error !== null}
          type="password"
          autoComplete={'current-password'}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={async () => {
            await signIn(email, password);
          }}
          disabled={status === 'loading'}
        >
          {status === 'loading' ? (
            <CircularProgress sx={{ color: '#fff' }} />
          ) : (
            'ログイン'
          )}
        </Button>
      </Box>
      <MaintenanceInfo />
      <LinkToForgotPassword sx={{ mt: 4 }} />
      <LinKToHelp sx={{ mt: 1 }} />
    </AuthRootWrapper>
  );
};
