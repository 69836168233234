import React from 'react';
import { Loading, ReportPresentation } from 'components';
import {
  useRequireAuth,
  useExchangeRates,
} from 'hooks';
import { useStore } from 'contexts';

export const ReportPage = () => {
  useRequireAuth();
  const { clientInfo, } = useStore()
  const exchangeRates = useExchangeRates();

  if (clientInfo.status === 'idle' || clientInfo.status === 'loading') {
    return <Loading />;
  }
  return (
    <ReportPresentation
      clientOrFamily={clientInfo.data?.client!}
      assets={clientInfo.data?.assets ?? []}
      barData={clientInfo.data?.historicalAggregationByAssetTypeMonthly ?? []}
      exchangeRates={exchangeRates}
    />
  );
};
