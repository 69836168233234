import {
  collection,
  doc,
  getDoc,
  query,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  useFirestoreCollection,
  useFirestoreDocument,
  useSnackbar,
} from 'hooks';
import { db } from 'initFirebase';
import { useEffect, useState } from 'react';
import { IInsightUrl } from 'types';

export const useInsightSheet = (docId: string) => {
  const collectionName = 'insightSlidesOnSpeakerDeck';
  const docRef = doc(db, collectionName, docId).withConverter(
    insightSheetConverter
  );
  const { data: insight, isLoading } = useFirestoreDocument(docRef);
  const [newInsightIframe, setNewInsightIFrame] = useState<string>('');
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  useEffect(() => {
    if (insight !== undefined) {
      setNewInsightIFrame(insight?.speakerDeckIFrame);
    }
  }, [insight !== undefined]);

  const onChangeNewIframe = (iFrame: string) => {
    setNewInsightIFrame(iFrame);
  };

  const save = async () => {
    try {
      await setDoc(
        docRef,
        {
          speakerDeckIFrame: newInsightIframe,
        },
        { merge: true }
      );
      showSuccessSnackbar('保存しました。');
    } catch (e) {
      console.error(e);
      showErrorSnackbar('エラーが発生しました。');
    }
  };

  return { insight, newInsightIframe, onChangeNewIframe, save };
};

const insightSheetConverter = {
  toFirestore: (sheetInput: IInsightUrl) => ({
    speakerDeckIFrame: sheetInput.speakerDeckIFrame,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions | undefined
  ) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      updatedAt: snapshot.data().updatedAt || null,
      speakerDeckIFrame: snapshot.data().speakerDeckIFrame,
    } as IInsightUrl;
  },
};
