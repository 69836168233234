import React from 'react';
import { Grid, Stack } from '@mui/material';
import {
  BrokerageAccountField,
  AccountTypeField,
  CurrencyField,
  ProductTypeField,
  ProductTypeAltField,
  UnitField,
  NumericalTextField,
  StringTextField,
  CacheUnitField,
} from './DialogParts';
import {
  IBbgDocData,
  INumericalFieldProps,
  IBbgDocIdProps,
  ITextFieldProps,
  ISelectProps,
} from './customHooks';

interface ICacheAdditionalPanel {
  productNameProps: ITextFieldProps;
  currencyProps: ISelectProps;
  unitProps: ISelectProps;
  brokerageAccountProps: ISelectProps;
  amountProps: INumericalFieldProps;
}
interface IIsinAdditionalPanel {
  bbgDocIdProps: IBbgDocIdProps;
  bbgDocData: IBbgDocData;
  brokerageAccountProps: ISelectProps;
  accountTypeProps: ISelectProps;
  amountProps: INumericalFieldProps;
  purchasePriceProps: INumericalFieldProps;
  paymentLocalProps: INumericalFieldProps;
  paymentDomesticProps: INumericalFieldProps;
  distributionLocalProps: INumericalFieldProps;
  salesProceedsLocalProps: INumericalFieldProps;
  salesProceedsDomesticProps: INumericalFieldProps;
}

interface IAlternativeAdditionalPanel {
  productNameProps: ITextFieldProps;
  currencyProps: ISelectProps;
  unitProps: ISelectProps;
  productTypeProps: ISelectProps;
  marketPriceProps: INumericalFieldProps;
  calcFlagProps: INumericalFieldProps;
  brokerageAccountProps: ISelectProps;
  amountProps: INumericalFieldProps;
  purchasePriceProps: INumericalFieldProps;
  paymentLocalProps: INumericalFieldProps;
  paymentDomesticProps: INumericalFieldProps;
  distributionLocalProps: INumericalFieldProps;
  salesProceedsLocalProps: INumericalFieldProps;
  salesProceedsDomesticProps: INumericalFieldProps;
}

const IsinAdditionPanel = ({
  bbgDocIdProps,
  bbgDocData,
  brokerageAccountProps,
  accountTypeProps,
  amountProps,
  purchasePriceProps,
  paymentLocalProps,
  paymentDomesticProps,
  distributionLocalProps,
  salesProceedsLocalProps,
  salesProceedsDomesticProps,
}: IIsinAdditionalPanel) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <StringTextField
            {...bbgDocIdProps}
            label="ISIN &middot; 証券コード*"
          />
          <StringTextField
            label="銘柄名*"
            value={bbgDocData.productName}
            disabled
          />
          <CurrencyField value={bbgDocData.currency} disabled />
          <UnitField value={bbgDocData.unit} disabled />
          <ProductTypeField value={bbgDocData.productType} disabled />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <BrokerageAccountField {...brokerageAccountProps} />
          <AccountTypeField {...accountTypeProps} />
          <NumericalTextField label="数量*" {...amountProps} />
          <NumericalTextField label="買付単価*" {...purchasePriceProps} />
          <NumericalTextField
            label="買付受払金額(現地)*"
            {...paymentLocalProps}
          />
          <NumericalTextField
            label="買付受払金額(円換算)*"
            {...paymentDomesticProps}
          />
          <NumericalTextField
            label="利金配当金計(現地)"
            {...distributionLocalProps}
          />
          <NumericalTextField
            label="売却代金(現地)"
            {...salesProceedsLocalProps}
          />
          <NumericalTextField
            label="売却代金(円換算)"
            {...salesProceedsDomesticProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const AlternativeAdditionPanel = ({
  productNameProps,
  currencyProps,
  unitProps,
  productTypeProps,
  marketPriceProps,
  calcFlagProps,
  brokerageAccountProps,
  amountProps,
  purchasePriceProps,
  paymentLocalProps,
  paymentDomesticProps,
  distributionLocalProps,
  salesProceedsLocalProps,
  salesProceedsDomesticProps,
}: IAlternativeAdditionalPanel) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <StringTextField label="銘柄名*" {...productNameProps} />
          <CurrencyField {...currencyProps} />
          <UnitField {...unitProps} />
          <ProductTypeAltField {...productTypeProps} />
          <NumericalTextField label="時価*" {...marketPriceProps} />
          <NumericalTextField label="計算式フラグ" {...calcFlagProps} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <BrokerageAccountField {...brokerageAccountProps} />
          <NumericalTextField label="数量*" {...amountProps} />
          <NumericalTextField label="買付単価*" {...purchasePriceProps} />
          <NumericalTextField
            label="買付受払金額(現地)*"
            {...paymentLocalProps}
          />
          <NumericalTextField
            label="買付受払金額(円換算)*"
            {...paymentDomesticProps}
          />
          <NumericalTextField
            label="利金配当金計(現地)"
            {...distributionLocalProps}
          />
          <NumericalTextField
            label="売却代金(現地)"
            {...salesProceedsLocalProps}
          />
          <NumericalTextField
            label="売却代金(円換算)"
            {...salesProceedsDomesticProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const CacheAdditionPanel = ({
  productNameProps,
  currencyProps,
  unitProps,
  brokerageAccountProps,
  amountProps,
}: ICacheAdditionalPanel) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <StringTextField label="名称*" {...productNameProps} />
          <CurrencyField {...currencyProps} />
          <CacheUnitField {...unitProps} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <BrokerageAccountField {...brokerageAccountProps} />
          <NumericalTextField label="数量*" {...amountProps} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export { IsinAdditionPanel, AlternativeAdditionPanel, CacheAdditionPanel };
