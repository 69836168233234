import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DatabaseReference,
  DataSnapshot,
  EventType,
  off,
  onValue,
  ref,
} from 'firebase/database';
import { auth, database } from 'initFirebase';
import { User } from 'firebase/auth';

export const useRedirectIfAdminGranted = (redirectPath = '/admin/clients') => {
  const navigate = useNavigate();
  let callback: null | ((snapshot: DataSnapshot) => void) = null;
  let metadataRef: DatabaseReference | null = null;

  const redirectIfAdmin = (user: User) => {
    user
      .getIdToken(true)
      .then(token => {
        return user.getIdTokenResult();
      })
      .then(idTokenResult => {
        const _isAdmin = idTokenResult.claims.admin as boolean | undefined;
        const userPath = '/' + user.uid; // TODO: This should be customizable
        const destination = _isAdmin ? redirectPath : userPath;
        navigate(destination);
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (callback && metadataRef) {
        const valueEvent = 'value' as EventType;
        off(metadataRef, valueEvent, callback);
      }
      if (user) {
        const dataPath = 'metadata/' + user.uid + '/refreshTime';
        metadataRef = ref(database, dataPath);
        callback = (snapshot: DataSnapshot) => {
          redirectIfAdmin(user);
        };
        onValue(metadataRef, callback);
        redirectIfAdmin(user);
      }
    });
    return unsubscribe;
  }, []);
};
