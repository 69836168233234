import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const Loading = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',
    }}
  >
    <CircularProgress />
  </Box>
);
