import { styled } from '@mui/material/styles';
import { getHSLValue } from './getHSLValue';

const LegendDiv = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const legendIconStyle = {
  height: '12px',
  width: '16px',
  margin: '0px 3px 0px 20px',
};

// TODO type of payload
export const CustomLegend = ({ payload }: { payload?: any[] }) => {
  if (payload === undefined) {
    return null;
  }
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {payload
        .map((p, i) => (
          <LegendDiv key={i}>
            <div
              style={{ ...legendIconStyle, backgroundColor: getHSLValue(i) }}
            ></div>
            <div>{p.payload.name}</div>
          </LegendDiv>
        ))
        .reverse()}
    </div>
  )
}
