import { useMentoringSheet, useInsightSheet, useClient, useAuth } from 'hooks';
import { Loading } from 'components/Loading';
import { DashboardPresentation } from 'components/Dashboard';
import { useStore } from 'contexts';

export const DashboardPage = () => {
  // useRequireAuth();
  const { clientInfo } = useStore();

  const clientId = clientInfo.data?.client?.id;
  const [{ sheets, isLoading }, _] = useMentoringSheet(
    clientInfo.data?.client?.id,
    true
  );
  // const [{ status, error, summary }, { fetchSummary }] = useClient();
  const { insight: commonInsight } = useInsightSheet('common');
  const { insight: indInsight } = useInsightSheet(clientId || '');
  const sheet = sheets?.at(0);

  if (clientInfo.status === 'loading' || isLoading) {
    return <Loading />;
  }
  if (sheet === undefined) {
    return <div>シートが登録されておりません。</div>; // TODO: Show if sheet not created or this is family
  }
  if (clientInfo.status === 'failed') {
    return <p>{clientInfo.error}</p>;
  }
  return (
    <DashboardPresentation
      sheet={sheet}
      clientName={clientInfo.data?.client?.name ?? 'お客'}
      totalMarketValueDomestic={
        clientInfo.data?.assetSummary?.marketValueDomestic ?? 0
      }
      personalizedInsightSpeakerDeckIFrame={
        commonInsight?.speakerDeckIFrame || ''
      }
      commonInsightSpeakerDeckIFrame={indInsight?.speakerDeckIFrame || ''}
    />
  );
};
