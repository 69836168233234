import { Typography, Container, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Footer } from './Footer';
import { ClientNameTypography } from './ClientNameTypography';
import { Summary } from './Summary';
import { Title } from './Title';
import { EvaluationDateTypography } from './EvaluationDateTypography';
import { StackedBarChart } from './StackedBarChart';
import { AssetPieChart } from './PieChart';
import { AggregationTable } from './AggregationTable';
import { japanizeDate } from 'utils';
import { ClientAssetHistoryAggregation, IAsset } from 'types/asset';

import { calcTotal } from './calcTotal';
import {
  AssetFilter,
  AssetTable,
  useAssetFilter,
  useAssetTableSorter,
  AssetTableToggle,
  AssetTableForMobile,
  filterAssets,
} from 'components';
import { useToggle } from 'hooks';
import { calcGroupBy } from './calcGroupBy';
import { ExchangeRates } from './ExchangeRates';
import { ExchangeRateLatest } from 'api/exchangeRates';
import { Family, NewApiClient } from 'types';

type ReportPresentationForClientProps<K extends string> = {
  clientOrFamily: NewApiClient | Family
  assets: IAsset[];
  barData: ClientAssetHistoryAggregation<K>[];
  exchangeRates: ExchangeRateLatest[];
}

export const ReportPresentation = <K extends string>({
  clientOrFamily,
  assets,
  barData,
  exchangeRates,
}: ReportPresentationForClientProps<K>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [detailVisible, toggleDetailVisible] = useToggle(false);
  const { order, orderBy, buildSorter } = useAssetTableSorter();
  const [soldVisible, toggleSoldVisible] = useToggle(false);
  const [filterOptions, setOptions] = useAssetFilter();

  const total = calcTotal(assets);
  const groupBy = calcGroupBy(assets);
  const filteredAssets = filterAssets(assets, filterOptions);

  return (
    <>
      <Container component="main" sx={{ pb: 3 }}>
        <ClientNameTypography>
          {clientOrFamily.name}様
        </ClientNameTypography>
        <EvaluationDateTypography>
          {"evaluatedAt" in clientOrFamily && clientOrFamily.evaluatedAt ? `評価日: ${japanizeDate(new Date(clientOrFamily.evaluatedAt))}` : ''}

        </EvaluationDateTypography>
        <Title titleName={'ポートフォリオ概要'} />
        <Summary total={total} />
        <Title titleName={'ポートフォリオ全体の推移(各月末評価額)'} />
        <StackedBarChart
          data={barData}
        />

        <Title titleName={'保有銘柄一覧'} />
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={8}>
              <AssetFilter
                allRows={assets!}
                setTableFilterOptions={setOptions}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AssetTableToggle
                toggleDetailVisible={toggleDetailVisible}
                toggleSoldVisible={toggleSoldVisible}
              />
            </Grid>
          </Grid>
        </Box>
        {isMobile ? (
          <AssetTableForMobile
            assets={filteredAssets}
            detailVisible={detailVisible}
            soldVisible={soldVisible}
            order={order}
            orderBy={orderBy}
            buildSorter={buildSorter}
          />
        ) : (
          <AssetTable
            assets={filteredAssets}
            detailVisible={detailVisible}
            soldVisible={soldVisible}
            order={order}
            orderBy={orderBy}
            buildSorter={buildSorter}
          />
        )}

        <Title titleName={'ポートフォリオ集計'} />
        <Typography fontSize={16}>単位: 百万円</Typography>
        <div style={{ height: 20 }} />
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} md={4}>
            <AggregationTable
              title="通貨別残高"
              labelName="通貨"
              data={groupBy.currency}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AggregationTable
              title="資産クラス別残高"
              labelName="資産クラス"
              data={groupBy.productType}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AggregationTable
              title="証券口座別残高"
              labelName="証券口座"
              data={groupBy.brokerageAccount}
            />
          </Grid>
        </Grid>
        <Title titleName={'ポートフォリオ集計（円グラフ）'} />
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} md={4}>
            <AssetPieChart
              data={groupBy.currency}
              label="通貨別残高 (百万円)"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AssetPieChart
              data={groupBy.productType}
              label="資産クラス別残高 (百万円)"
              hueOffset={120}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AssetPieChart
              data={groupBy.brokerageAccount}
              label="証券口座別残高 (百万円)"
              hueOffset={200}
            />
          </Grid>
        </Grid>
        {/* <Title titleName={'キャッシュフロー'} /> */}
        <ExchangeRates exchangeRates={exchangeRates} />
      </Container>
      <Footer />
    </>
  );
};
