import { DashboardPresentation } from 'components/Dashboard';
import { Loading } from 'components/Loading';
import { useMentoringSheet, useClientV0, useClient } from 'hooks';
import { useInsightSheet } from 'hooks';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const AdminClientDashboardPage = () => {
  const { clientId } = useParams();
  const { client } = useClientV0(clientId);
  if (clientId === undefined) {
    throw new Error('clientId is undefined');
  }
  const [{ sheets, isLoading }, _] = useMentoringSheet(clientId, false);
  const sheet = sheets?.at(0);
  const [{ status, error, summary }, { fetchSummary }] = useClient();
  const { insight: commonInsight } = useInsightSheet('common');
  const { insight: indInsight } = useInsightSheet(clientId || '');

  useEffect(() => {
    if (status === 'idle') {
      fetchSummary(clientId);
    }
  }, [status]);

  if (isLoading || status === 'loading') {
    return <Loading />;
  }
  if (sheet === undefined) {
    return (
      <div>まだシートが登録されておりません。担当にお問い合わせください。</div>
    );
  }

  if (status === 'failed') {
    return <p>資産総額の取得に失敗しました</p>;
  }
  return (
    <DashboardPresentation
      sheet={sheet}
      clientName={client?.name || 'お客'}
      totalMarketValueDomestic={summary?.marketValueDomestic ?? 0}
      personalizedInsightSpeakerDeckIFrame={indInsight?.speakerDeckIFrame || ''}
      commonInsightSpeakerDeckIFrame={commonInsight?.speakerDeckIFrame || ''}
    />
  );
};
