import { db } from 'initFirebase';
import {
  collection,
  doc,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  where,
} from 'firebase/firestore';
import { useAuth, useFirestoreDocument } from 'hooks';
import { IClient } from 'types/client';
import { useFirestoreCollection } from './useFirestore';
import { useState } from 'react';
import { getClientFinancialSummary } from 'api';

const getType = (
  client: IClient | undefined,
  clients: IClient[] | undefined
): 'singleClient' | 'clientWithFamily' | 'family' | undefined => {
  if (client !== undefined) {
    if (client.familyId !== undefined) {
      return 'clientWithFamily';
    }
    return 'singleClient';
  }
  if (clients !== undefined && clients.length > 0) {
    return 'family';
  }
  return undefined;
};

/**
 * TODO
 * @param clientId
 * @returns
 */
export const useClientV0 = (clientId: string | undefined) => {
  const docRef =
    clientId !== undefined
      ? doc(db, 'clients', clientId).withConverter(clientConverter)
      : undefined;
  const q =
    clientId !== undefined
      ? query(
          collection(db, 'clients'),
          where('familyId', '==', clientId)
        ).withConverter(clientConverter)
      : undefined;
  const { isLoading, data: client, error } = useFirestoreDocument(docRef);
  const { data: clients } = useFirestoreCollection(q);
  const type = getType(client, clients);
  const familyId = client?.familyId || clients?.at(0)?.familyId || undefined;
  return { isLoading, client, error, clients, type };
};

export const clientConverter = {
  toFirestore: (client: IClient) => ({
    email: client.email,
    name: client.name,
    passwordUpdatedAt: client.passwordUpdatedAt,
    ...(client.familyId !== undefined && { familyId: client.familyId }),
    ...(client.familyName !== undefined && { familyName: client.familyName }),
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions | undefined
  ) => {
    const { manuallyUpdatedAt, passwordUpdatedAt, ...data } =
      snapshot.data(options);
    return {
      id: snapshot.id,
      passwordUpdatedAt: passwordUpdatedAt
        ? (passwordUpdatedAt as Timestamp).toDate()
        : null,
      ...(manuallyUpdatedAt && {
        manuallyUpdatedAt: manuallyUpdatedAt.toDate(),
      }),
      ...data,
    } as IClient;
  },
};

export const useClient = () => {
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'failed'
  >('idle');
  const [error, setError] = useState<string | null>(null);
  const [summary, setSummary] = useState<{
    marketValueDomestic: number;
    PLDomestic: number;
    capitalGainDomestic: number;
    unrealizedGainDomestic: number;
    PLInclDistributionDomestic: number;
    distributionDomestic: number;
  } | null>(null);
  const { currentUser } = useAuth();
  const fetchSummary = async (id: string) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const data = await getClientFinancialSummary({id, token});
      setSummary(data);
      setStatus('success');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  return [
    {
      status,
      error,
      summary,
    },
    { fetchSummary },
  ] as const;
};
