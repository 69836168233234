import React from 'react';
import { Outlet } from 'react-router-dom';

export const AdminClientsLayout = () => {
  return (
    <>

      <Outlet />
    </>
  );
};
