import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IAssetTableMenu {
  toggleDetailVisible: () => void;
  toggleSoldVisible: () => void;
}

export const AssetTableToggle = ({
  toggleDetailVisible,
  toggleSoldVisible,
}: IAssetTableMenu) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ display: 'flex', ml: 1, my: 1 }}>
      {isMobile ? (
        <></>
      ) : (
        <FormGroup>
          <FormControlLabel
            control={<Switch size="small" />}
            label={<Typography variant="caption">詳細を表示</Typography>}
            onChange={toggleDetailVisible}
          />
        </FormGroup>
      )}
      <FormGroup>
        <FormControlLabel
          control={<Switch size="small" />}
          label={<Typography variant="caption">売却済み銘柄を表示</Typography>}
          onChange={toggleSoldVisible}
        />
      </FormGroup>
    </Box>
  );
};
