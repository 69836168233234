import { MentoringNetWorthGoalInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { TextFieldProps } from '@mui/material';

interface IAssetGoalInputField {
  assetGoal: number;
  onChange: (value: number) => void;
}

//TODO: commaize
export const NetWorthInputField = ({
  assetGoal,
  onChange,
}: IAssetGoalInputField) => (
  <MentoringNetWorthGoalInputBox
    icon={<SquareRoundedIcon style={{ color: 'hsl(0, 90%, 80%)' }} />}
    value={assetGoal}
    onChange={onChange}
    hoverBorderColor="hsl(0, 40%, 80%)"
    focusedBorderColor="hsl(0, 65%, 70%)"
    title="資産時価総額目標"
  />
);
