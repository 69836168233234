import React, { useEffect } from 'react';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';

export const useRequireAdmin = (redirectUrl = '/admin/signin') => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === undefined) {
      return;
    }
    if (currentUser === null) {
      navigate(redirectUrl);
      return;
    }

    currentUser.getIdTokenResult().then(result => {
      const isAdmin = result.claims.admin as boolean | undefined;
      if (!isAdmin) {
        navigate(redirectUrl);
      }
    });
  }, [currentUser, navigate]);
};
