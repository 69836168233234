import { MentoringInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

interface INextActionsInputField {
  nextActions: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NextActionsInputField = ({
  nextActions,
  onChange,
}: INextActionsInputField) => {
  return (
    <MentoringInputBox
      icon={<SquareRoundedIcon style={{ color: 'hsl(200, 90%, 80%)' }} />}
      value={nextActions}
      onChange={onChange}
      hoverBorderColor="hsl(200, 40%, 80%)"
      focusedBorderColor="hsl(200, 65%, 70%)"
      title="次のアクション"
      multiline
      minRows={4}
    />
  );
};
