export const handleSignInErrorCode = (authErrorCode: string) => {
  switch (authErrorCode) {
    // Reference: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
    case 'auth/invalid-email':
      return {
        emailErrorMessage: '無効なメールアドレスです.',
        passwordErrorMessage: '',
      };
    case 'auth/user-disabled':
      return {
        emailErrorMessage: 'アカウントが無効になっています.',
        passwordErrorMessage: '',
      };
    case 'auth/user-not-found':
      return {
        emailErrorMessage: 'ユーザーが見つかりません.',
        passwordErrorMessage: '',
      };
    case 'auth/wrong-password':
      return {
        emailErrorMessage: '',
        passwordErrorMessage: 'パスワードが間違っています.',
      };
    case 'auth/too-many-requests':
      return {
        emailErrorMessage:
          '大量のログイン試行が検出されました. 時間を置いてお試しください.',
        passwordErrorMessage: '',
      };
    default:
      return {
        emailErrorMessage: '',
        passwordErrorMessage: '',
      };
  }
};

export const handleSignUpErrorCode = (authErrorCode: string) => {
  switch (authErrorCode) {
    // Reference: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
    case 'auth/email-already-in-use':
      return {
        emailErrorMessage: 'メールアドレスがすでに登録済みです.',
        passwordErrorMessage: '',
      };
    case 'auth/invalid-email':
      return {
        emailErrorMessage: '無効なメールアドレスです.',
        passwordErrorMessage: '',
      };
    case 'auth/operation-not-allowed':
      return {
        emailErrorMessage:
          'メールアドレスとパスワードによる登録を現在受け付けておりません.',
        passwordErrorMessage: '',
      };
    case 'auth/weak-password':
      return {
        emailErrorMessage: '',
        passwordErrorMessage:
          'パスワードが弱すぎます。6文字以上にしてください.',
      };
    default:
      return {
        emailErrorMessage: '',
        passwordErrorMessage: '',
      };
  }
};
