import { MentoringInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

interface IGoalInputField {
  goal: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FinancialGoalInputField = ({
  goal,
  onChange,
}: IGoalInputField) => {
  return (
    <MentoringInputBox
      icon={<SquareRoundedIcon style={{ color: 'hsl(40, 90%, 80%)' }} />}
      value={goal}
      onChange={onChange}
      hoverBorderColor="hsl(40, 40%, 80%)"
      focusedBorderColor="hsl(40, 65%, 70%)"
      title="フィナンシャルゴール"
      multiline
      minRows={4}
    />
  );
};
