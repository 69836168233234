import React from 'react';
import { TableCell, TableCellProps } from '@mui/material';
import { commaize } from 'utils';

interface SignAwareTableCellProps {
  value: number | null | undefined;
  align: TableCellProps['align'];
  padding: TableCellProps['padding'];
  decimalPoints: number;
}

export const SignAwareTableCell = ({
  value,
  align,
  padding,
  decimalPoints,
}: SignAwareTableCellProps) => {
  const isNegative =
    value !== undefined && value !== null && Math.sign(value) < 0;
  const color = isNegative ? 'red' : undefined;
  return (
    <TableCell
      sx={{
        color: color,
        px: 1,
        py: 0,
        fontSize: 14,
      }}
      align={align}
    >
      {value !== null && value !== undefined
        ? commaize(value, decimalPoints)
        : ''}
    </TableCell>
  );
};
