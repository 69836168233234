import { MentoringInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

interface IReviewInputField {
  review: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ReviewInputField = ({ review, onChange }: IReviewInputField) => {
  return (
    <MentoringInputBox
      icon={<SquareRoundedIcon style={{ color: 'hsl(160, 90%, 80%)' }} />}
      value={review}
      onChange={onChange}
      hoverBorderColor="hsl(160, 40%, 80%)"
      focusedBorderColor="hsl(160, 65%, 70%)"
      title="振り返り"
      multiline
      minRows={4}
    />
  );
};
