import {
  collection,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { db } from 'initFirebase';

import { useFirestoreCollection } from 'hooks';
import { IFamilyOption } from 'types/family';

export const useFamilyOptions = () => {
  const colRef = collection(db, 'families').withConverter<IFamilyOption>(
    familyOptionConverter
  );
  const q = query(colRef);
  const { data: familyOptions, isLoading } = useFirestoreCollection(q);
  return { familyOptions, isLoading };
};

export const familyOptionConverter = {
  toFirestore: (family: IFamilyOption) => ({
    email: family.name,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions | undefined
  ) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
    } as IFamilyOption;
  },
};
