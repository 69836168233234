import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { commaize, formatDate } from 'utils';
import { compatMonth, isOlddata } from './compat';

export const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, NameType>) => {
  if (!(active && payload && payload.length > 0)) {
    return null;
  }
  const _d = new Date(label);
  const d = isOlddata(_d) ? compatMonth(_d) : _d;
  const dateString = formatDate(d, '%Y/%m');
  return (
    <div
      style={{
        backgroundColor: '#fff',
        outline: 'solid thin #ddd',
        padding: '2px 20px',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>{dateString}</p>
      {payload
        .map((p, i) => (
          <p key={p.dataKey}>
            {p.name}: {p.value && commaize(Math.round(p.value / 1e6))} 百万円
          </p>
        ))
        .reverse()}
    </div>
  );
};
