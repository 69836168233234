import { TableRow, TableCell } from '@mui/material';
import { IAsset, TotalAsset } from 'types/asset';
import { SignAwareTableCell } from './SignAwareTableCell';

export const calcTotal = (rows: IAsset[]): TotalAsset => {
  const _calcNextValue = (
    a: number | null,
    cValue: number | null | undefined
  ) => {
    if (a === null) {
      return null;
    }
    if (cValue === null || cValue === undefined) {
      return null;
    }
    return a + cValue;
  };
  const calcTotalByField = (
    rows: IAsset[],
    fn: (asset: IAsset) => number | undefined | null
  ) => {
    const total = rows.reduce<number | null>((a, c) => {
      const cValue = fn(c);
      return _calcNextValue(a, cValue);
    }, 0);
    return total;
  };
  const marketValueDomestic = calcTotalByField(
    rows,
    c => c.marketValueDomestic
  );
  const PLDomestic = calcTotalByField(rows, c => c.PLDomestic);
  const capitalGainDomestic = calcTotalByField(
    rows,
    c => c.capitalGainDomestic
  );
  const unrealizedGainDomestic = calcTotalByField(
    rows,
    c => c.unrealizedGainDomestic
  );
  const PLInclDistributionDomestic = calcTotalByField(
    rows,
    c => c.PLInclDistributionDomestic
  );
  const distributionDomestic = calcTotalByField(
    rows,
    c => c.distributionDomestic
  );
  const total = {
    marketValueDomestic,
    PLDomestic,
    capitalGainDomestic,
    unrealizedGainDomestic,
    PLInclDistributionDomestic,
    distributionDomestic,
  };
  return total;
};

interface TotalTableRowProps {
  total: TotalAsset;
  detailVisible: boolean;
}

export const TotalTableRow = ({ total, detailVisible }: TotalTableRowProps) => (
  <>
    <TableRow>
      <TableCell
        sx={{
          padding: 1,
          position: 'sticky',
          left: 0,
          whiteSpace: 'unset',
          backgroundColor: '#fafafa',
          fontSize: 14,
          fontWeight: 'bold',
        }}
        rowSpan={2}
      >
        合計
      </TableCell>
      <TableCell
        key="purchasePrice"
        align="right"
        padding="normal"
        sx={{ whiteSpace: 'nowrap', padding: '20px' }}
      />
      <SignAwareTableCell
        key="marketValueDomestic"
        value={
          total['marketValueDomestic'] !== undefined
            ? total['marketValueDomestic']
            : null
        }
        padding="normal"
        align="right"
        decimalPoints={0}
      />
    </TableRow>
    <TableRow>
      <TableCell
        key="marketPrice"
        align="right"
        padding="normal"
        sx={{ whiteSpace: 'nowrap', padding: '20px' }}
      />
      <SignAwareTableCell
        key="PLDomestic"
        value={total['PLDomestic'] !== undefined ? total['PLDomestic'] : null}
        padding="normal"
        align="right"
        decimalPoints={0}
      />
    </TableRow>
  </>
);
