import { QueryDocumentSnapshot } from 'firebase/firestore';
import { FinancialProduct } from 'types/models/financialProduct';
import { FinancialProductFirestore } from 'types/vendor/FinancialProductFirestore';

export const financialProductConverter = {
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    const data = snap.data() as FinancialProductFirestore;
    const product: FinancialProduct = {
      id: snap.id,
      ...data,
      maturity: data.maturity?.toDate() || null,
      evaluatedAt: data.evaluatedAt?.toDate() || null,
      first_call_dt_issuance: data.first_call_dt_issuance?.toDate() || null,
      nxt_call_dt: data.nxt_call_dt?.toDate() || null,
      nxt_cpn_dt: data.nxt_cpn_dt?.toDate() || null,
      prev_cpn_dt: data.prev_cpn_dt?.toDate() || null,
      rd_override: data.rd_override?.toDate() || null,
      updatedAt: data.updatedAt?.toDate() || null,
      evaluationDate: data.evaluationDate?.toDate() || null,
    };
    return product;
  },
  toFirestore: (product: FinancialProduct) => {
    const { id, ...data } = product;
    return data;
  },
};
