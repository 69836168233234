import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { NewApiClient } from 'types';
import { Family } from 'types/family';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface SelectAccountProps {
  clients: NewApiClient[];
  family: Family;
}

export const SelectClient = ({
  clients,
  family,
}: SelectAccountProps) => {
  const navigate = useNavigate();
  const { clientId, familyId } = useParams();
  const location = useLocation();
  return (
    <FormControl sx={{ minWidth: 120, ml: { xs: 1, sm: 2 } }} size="small" >
      <Select
        // disableUnderline
        value={clientId ?? familyId ?? ''}
        onChange={(event: SelectChangeEvent) => {
          const selectedId = event.target.value;
          let suffix = '';
          let prefix = '';
          if (location.pathname.endsWith('/report')) {
            suffix = '/report';
          }
          if (location.pathname.endsWith('/assets')) {
            suffix = '/assets';
          }
          if (location.pathname.endsWith('/mentoring')) {
            suffix = '/mentoring';
          }
          if (location.pathname.includes("admin")) {
            prefix = '/admin'
          }
          if (selectedId === family.id) {
            navigate(`${prefix}/families/${family.id}${suffix}`);
          } else {
            navigate(`${prefix}/clients/${selectedId}${suffix}`);
          }
        }}
        autoWidth
        sx={{ fontWeight: 'bold', fontSize: { xs: 14, sm: 16 } }}
      >
        {clients.map(c => (
          <MenuItem key={c.id} value={c.id}>
            {c.name}様
          </MenuItem>
        ))}
        <Divider />
        <MenuItem key={family.id!} value={family.id!}>
          {family.name}
        </MenuItem>
      </Select>
    </FormControl >
  )
};

