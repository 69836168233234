import React from 'react';
import { IAsset } from 'types/asset';

const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID as string;
const baseUrl = `https://asia-northeast1-${projectId}.cloudfunctions.net/downloadExcel?`;

export const _downloadExcelByClientId = async (
  clientId: string,
  orderBy?: keyof IAsset,
  order?: 'asc' | 'desc'
) => {
  const params = new URLSearchParams();
  if (orderBy) {
    const orderSign = order === 'asc' ? '' : '-';
    params.append('orderBy', orderSign + orderBy);
  }

  params.append('clientId', clientId);

  const funcUrl =
    Array.from(params).length > 0 ? baseUrl + params.toString() : baseUrl;
  const res = await fetch(funcUrl);
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `Pragmaworks銘柄一覧_${new Date().toJSON().slice(0, 10)}.xlsx`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

export const _downloadExcelForAllClients = async (
  orderBy?: keyof IAsset,
  order?: 'asc' | 'desc'
) => {
  const params = new URLSearchParams();
  if (orderBy) {
    const orderSign = order === 'asc' ? '' : '-';
    params.append('orderBy', orderSign + orderBy);
  }
  const baseUrl = `https://asia-northeast1-${projectId}.cloudfunctions.net/downloadExcel`;
  const funcUrl = baseUrl + params.toString();
  const res = await fetch(funcUrl);
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `Pragmaworks銘柄一覧_${new Date().toJSON().slice(0, 10)}.xlsx`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

export const useDownloadExcel = () => {
  const [status, setStatus] = React.useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const downloadExcelByClientId = async (
    clientId: string,
    orderBy?: keyof IAsset,
    order?: 'asc' | 'desc'
  ) => {
    setStatus('loading');
    setErrorMessage(null);
    try {
      await _downloadExcelByClientId(clientId, orderBy, order);
      setStatus('success');
    } catch (e) {
      setStatus('error');
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage('エラーが発生しました');
      }
    }
  };

  const downloadExcelForAllClients = async (
    orderBy?: keyof IAsset,
    order?: 'asc' | 'desc'
  ) => {
    setStatus('loading');
    setErrorMessage(null);
    try {
      await _downloadExcelForAllClients(orderBy, order);
      setStatus('success');
    } catch (e) {
      setStatus('error');
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage('エラーが発生しました');
      }
    }
  };
  return [
    { status, errorMessage },
    { downloadExcelByClientId, downloadExcelForAllClients },
  ] as const;
};
