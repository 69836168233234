import { MentoringInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

interface IResultInputField {
  result: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ResultInputField = ({ result, onChange }: IResultInputField) => {
  return (
    <MentoringInputBox
      icon={<SquareRoundedIcon style={{ color: 'hsl(120, 90%, 80%)' }} />}
      value={result}
      onChange={onChange}
      hoverBorderColor="hsl(120, 40%, 80%)"
      focusedBorderColor="hsl(120, 65%, 70%)"
      title="結果"
      multiline
      minRows={4}
    />
  );
};
