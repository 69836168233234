import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { SignInPage } from 'pages/signin';
import { AuthProvider, StoreProvider } from 'contexts';
import { ClientLayout } from 'pages/[:clientId]/ClientLayout';
import { MentoringPage } from 'pages/[:clientId]/mentoring';
import { ReportPage } from 'pages/[:clientId]/report';
import { FamilyReportPage } from 'pages/families/report';
import { AdminLayout } from 'pages/admin/AdminLayout';
import { AdminSettingsPage } from 'pages/admin/settings';
import { AdminSignInPage } from 'pages/admin/signin';
import { AdminSignUpPage } from 'pages/admin/signup';
import { InsightsPage } from 'pages/admin/insights';
import { DashboardPage } from 'pages/[:clientId]';
import { AdminClientLayout } from 'pages/admin/clients/[clientId]/AdminClientLayout';
import { AdminClientDashboardPage } from 'pages/admin/clients/[clientId]/adminDashboard';
import { AssetsPage } from 'pages/admin/clients/[clientId]/assets';
import { AdminReportPage } from 'pages/admin/clients/[clientId]/report';
import { AdminMentoringPage } from 'pages/admin/clients/[clientId]/mentoring';
import { AdminClientsLayout } from 'pages/admin/clients/AdminClientsLayout';
import { ClientsPage } from 'pages/admin/clients';
import { RootPage } from 'pages';
import { UpdatePasswordPage } from 'pages/update-password';
import { SnackbarProvider } from 'components/Snackbar';
import { theme } from 'createTheme';
import { ResetPasswordPage } from 'pages/reset-password';
import { UpdateEmailPage } from 'pages/update-email';
import { AdminMentoringLayout } from 'pages/admin/clients/[clientId]/mentoring/AdminMentoringLayout';
import { AdminClientInsightPage } from 'pages/admin/clients/[clientId]/adminClientInsight';
import { FamiliesPage } from 'pages/admin/families';
import { FamilyLayout } from 'pages/families/familyLayout';
import { AdminFamilyLayout } from 'pages/admin/families/AdminFamilyLayout';
import { AdminFamilyReportPage } from 'pages/admin/families/[:familyid]/AdminFamilyReportPage';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <BrowserRouter>
            <StoreProvider>
              <Routes>
                <Route path="signin" element={<SignInPage />} />
                <Route path="update-password" element={<UpdatePasswordPage />} />
                <Route path="reset-password" element={<ResetPasswordPage />} />
                <Route path="update-email" element={<UpdateEmailPage />} />
                <Route index element={<RootPage />} />
                <Route path="clients">
                  <Route path=":clientId" element={<ClientLayout />}>
                    <Route index element={<DashboardPage />} />
                    <Route path="assets" element={<AssetsPage />} />
                    <Route path="mentoring" element={<MentoringPage />} />
                    <Route path="report" element={<ReportPage />} />
                  </Route>
                </Route>
                <Route path="families">
                  <Route path=":familyId" element={<FamilyLayout />}>
                    <Route path="assets" element={<AssetsPage />} />
                    <Route path="report" element={<FamilyReportPage />} />
                  </Route>
                </Route>
                <Route path="admin" element={<AdminLayout />}>
                  {/* <Route index element={<AdminDashboard />} /> */}
                  <Route path="signin" element={<AdminSignInPage />} />
                  <Route path="signup" element={<AdminSignUpPage />} />
                  <Route path="insights" element={<InsightsPage />} />
                  <Route path="settings" element={<AdminSettingsPage />} />
                  <Route path="families" >
                    <Route index element={<FamiliesPage />} />
                    <Route path=":familyId" element={<AdminFamilyLayout />}>
                      <Route path="report" element={<AdminFamilyReportPage />} />
                    </Route>
                  </Route>
                  <Route path="clients" element={<AdminClientsLayout />}>
                    <Route index element={<ClientsPage />} />
                    <Route path=":clientId" element={<AdminClientLayout />}>
                      <Route index element={<AdminClientDashboardPage />} />
                      <Route path="mentoring" element={<AdminMentoringLayout />}>
                        <Route index element={<AdminMentoringPage />} />
                      </Route>
                      <Route
                        path="insight"
                        element={<AdminClientInsightPage />}
                      />
                      <Route path="assets" element={<AssetsPage />} />
                      <Route path="report" element={<AdminReportPage />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="*" element={<div>404</div>} />
              </Routes>
            </StoreProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </AuthProvider >
    </ThemeProvider >
  );
}
