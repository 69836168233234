import { formatDate } from 'utils';
import { compatMonth, isOlddata } from './compat';

export const makeXLabel = (unixtime: number) => {
  const _d = new Date(unixtime);
  const d = isOlddata(_d) ? compatMonth(_d) : _d;
  const dateFormatString = '%-m';
  return formatDate(d, dateFormatString);
};

export const calcYLimMax = (maxY: number) => {
  // round up for one significant digits
  // eg.: 1 => 1, 9=> 10, 12 => 20, 123 => 200, 900 => 1000
  const maxYInt = Math.floor(maxY);
  const powerOf10 = 10 ** (maxYInt.toString().length - 1);

  const base = Math.floor(maxYInt / powerOf10) * powerOf10;
  const roundedUpPrecision1 = base + powerOf10;
  return roundedUpPrecision1;
};
