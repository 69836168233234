import { IAsset } from 'types/asset';

export const calcTotal = (assets: IAsset[] | undefined) => {
  const _calcNextValue = (
    a: number | null,
    cValue: number | null | undefined
  ) => {
    if (a === null) {
      return null;
    }
    if (cValue === null || cValue === undefined) {
      return null;
    }
    return a + cValue;
  };
  const calcSummation = (fn: (asset: IAsset) => number | undefined | null) => {
    const res =
      assets?.reduce<number | null>((a, c) => {
        const cValue = fn(c);
        return _calcNextValue(a, cValue);
      }, 0) || 0;
    return res;
  };

  const totalMarketValueDomestic = calcSummation(c => c.marketValueDomestic);
  const totalPLDomestic = calcSummation(c => c.PLDomestic);
  const totalCapitalGainDomestic = calcSummation(c => c.capitalGainDomestic);
  const totalUnrealizedGainDomestic = calcSummation(
    c => c.unrealizedGainDomestic
  );
  const totalPLInclDistributionDomestic = calcSummation(
    c => c.PLInclDistributionDomestic
  );
  const totalDistributionDomestic = calcSummation(c => c.distributionDomestic);

  const total = {
    marketValueDomestic: totalMarketValueDomestic,
    PLDomestic: totalPLDomestic,
    capitalGainDomestic: totalCapitalGainDomestic,
    unrealizedGainDomestic: totalUnrealizedGainDomestic,
    PLInclDistributionDomestic: totalPLInclDistributionDomestic,
    distributionDomestic: totalDistributionDomestic,
  };
  return total;
};
