import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { isDeprecatedSheet } from 'hooks';
import { MentoringSheet } from 'types';
import { formatDate } from 'utils';

interface SelectSheetProps {
  sheets: MentoringSheet[];
  sheet: MentoringSheet;
  onChange: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
}

const createMenuItemLabel = (sheet: MentoringSheet) => {
  if (isDeprecatedSheet(sheet)) {
    return sheet.title;
  }
  let text = formatDate(sheet.meetingDate, '%Y年%m月%d日');
  if (sheet.isDraft) {
    text += ' (下書き)';
  }
  return text;
};

export const SelectSheet = ({ sheets, sheet, onChange }: SelectSheetProps) => (
  <FormControl variant="standard" sx={{ minWidth: 165 }}>
    <InputLabel>ミーティング日</InputLabel>
    <Select value={sheet.id} onChange={onChange}>
      {sheets.map(sheet => {
        const label = createMenuItemLabel(sheet);
        return (
          <MenuItem value={sheet.id} key={sheet.id}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);
