import {
  Button,
  Table,
  Dialog,
  DialogActions,
  DialogContent,
  TableBody,
  DialogTitle,
  Box,
  TableRow,
  TableCell,
} from '@mui/material';
import { IAsset } from 'types/asset';
import { columnOrder } from '../AssetTable/columnOrder';
import { SignAwareTableCell } from './SignAwareTableCell';
import { formatDate } from 'utils';

type AssetDetailDialogProps = {
  open: boolean;
  close: () => void;
  asset: IAsset;
};

export const AssetDetailDialog = ({
  open,
  close,
  asset,
}: AssetDetailDialogProps) => (
  <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
    <DialogTitle>銘柄詳細</DialogTitle>
    <DialogContent>
      <AssetPanel asset={asset} />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={close}>
        閉じる
      </Button>
    </DialogActions>
  </Dialog>
);

const AssetPanel = ({ asset }: { asset: IAsset }) => (
  <Box>
    <Table size="small">
      <TableBody>
        {columnOrder.map(c =>
          c.valueType === 'number' ? (
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#fafafa',
                  fontSize: 14,
                }}
              >
                {c.label}
              </TableCell>
              <SignAwareTableCell
                key={c.key}
                align="right"
                value={asset[c.key]}
                padding="normal"
                decimalPoints={
                  c.decimalPoints === 'cent'
                    ? asset.currency === 'JPY'
                      ? 0
                      : 2
                    : c.decimalPoints
                }
              />
            </TableRow>
          ) : c.valueType === 'Date' ? (
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#fafafa',
                  fontSize: 14,
                }}
              >
                {c.label}
              </TableCell>
              <TableCell
                align={c.align}
                padding={c.padding}
                sx={{ fontSize: 14, px: 1, py: 0 }}
              >
                {asset[c.key] !== undefined
                  ? formatDate(asset[c.key], '%-m月%d日')
                  : '-'}
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#fafafa',
                  fontSize: 14,
                }}
              >
                {c.label}
              </TableCell>
              <TableCell
                key={c.key}
                align="right"
                sx={{ fontSize: 14, px: 1, py: 0 }}
              >
                {asset[c.key]}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  </Box>
);
