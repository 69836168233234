import { Loading } from 'components/Loading';
import { useAuth } from 'hooks';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

export const RootPage = () => {
  const { currentUser } = useAuth();
  console.log(currentUser)
  if (currentUser === null) {
    return <Navigate to="/signin" />;
  }
  if (currentUser === undefined) {
    return <Loading />;
  }
  const dashboardPath = '/clients/' + currentUser.uid;
  return <Navigate to={dashboardPath} />;
};
