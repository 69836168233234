import { useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import {
  useAuth, useRequireAdmin,

} from 'hooks';
import { useStore } from 'contexts';
import { SelectClient, Loading, AdminClientsTabs } from 'components';


export const AdminClientLayout = () => {
  useRequireAdmin();

  const { clientId } = useParams() as { clientId: string };
  const { clientInfo, familyInfo, selectClient, selectFamily } = useStore();
  const { currentUser } = useAuth();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    selectClient(clientId)
  }, [currentUser, clientId]);


  if (clientInfo.status === 'idle' || clientInfo.status === 'loading' || familyInfo.status === 'idle' || familyInfo.status === 'loading') {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{ minWidth: 120, borderBottom: 'solid 1px #ddd' }}
        display="flex"
        alignItems="center"
      >
        <IconButton onClick={() => {
          navigate('/admin/clients');
        }}>
          <ArrowBackRoundedIcon sx={{ verticalAlign: 'bottom', ml: '2px' }} />
        </IconButton>
        {familyInfo?.data?.clients &&
          <SelectClient
            clients={familyInfo.data?.clients ?? []}
            family={familyInfo?.data?.family}
          />
        }
        <AdminClientsTabs
          sx={{ mx: 2 }}
          clientId={clientInfo.data?.client?.id ?? ''}
          currentPath={pathname}
        />
      </Box >
      <Outlet />
    </>
  );
};
