import {
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  InputBaseComponentProps,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import { useToggle } from 'hooks';
import { Theme, SxProps } from '@mui/system';
import { useState } from 'react';

interface PasswordTextFieldProps {
  label: string;
  value: string;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: InputBaseComponentProps | undefined;
  sx?: SxProps<Theme> | undefined;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  helperText?: string;
  fullWidth?: boolean;
  error?: boolean;
  autoComplete?: string;
}

function generatePassword() {
  const length = 8;
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const PasswordTextField = ({
  label,
  value,
  onChange,
  id,
  inputProps,
  sx,
  variant = 'outlined',
  helperText = '',
  fullWidth = false,
  error = false,
  autoComplete,
}: PasswordTextFieldProps) => {
  const [_isPasswordVisible, _toggleIsPasswordVisible] = useToggle(false);
  const [password, setPassword] = useState(value);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
    if (onChange) {
      onChange({
        target: { value: newPassword },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <FormControl sx={sx} fullWidth={fullWidth} variant={variant} error={error}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        type={_isPasswordVisible ? 'text' : 'password'}
        inputProps={inputProps || {}}
        value={password}
        autoComplete={autoComplete}
        onChange={handlePasswordChange}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title="パスワードを生成する">
              <IconButton
                aria-label="generate password"
                onClick={handleGeneratePassword}
                onMouseDown={event => event.preventDefault()}
              >
                <KeyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="パスワードを表示する">
              <IconButton
                aria-label="toggle password visibility"
                onClick={_toggleIsPasswordVisible}
                onMouseDown={event => event.preventDefault()}
              >
                {_isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
