import React, { useState } from 'react';
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { Invitee } from './hooks';

interface AdminInvitationProps {
  invitees: Invitee[];
  addInvitee: (newInvitee: Invitee) => Promise<{ status: string }>;
}

export const AdminInvitation = ({
  invitees,
  addInvitee,
}: AdminInvitationProps) => {
  const [email, setEmail] = useState<string>('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const invitee = new Invitee(email, false);
    addInvitee(invitee)
      .then(() => {
        setEmail('');
      })
      .catch(e => {
        //TODO: show much better feedback
        alert(e.message);
      });
  };
  return (
    <Box sx={{ maxWidth: '850px' }}>
      <Typography fontWeight="bold" sx={{ mb: 2 }}>
        管理者招待
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={7} sx={{ mr: 1, mb: 1 }}>
          <TextField
            size="small"
            fullWidth
            value={email}
            onChange={handleChange}
            label="招待したい人のメールアドレス"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button color="secondary" variant="contained" onClick={onClick}>
            招待メールを送る
          </Button>
        </Grid>
      </Grid>
      <Typography fontWeight="bold" color="#555" fontSize="1rem" sx={{ mt: 4 }}>
        招待済み管理者一覧
      </Typography>
      <List>
        <ListItem>
          <Grid container>
            <Grid item xs={8} sm={5}>
              <Typography fontSize="1rem">メールアドレス</Typography>
            </Grid>
            <Grid item xs={4} sm={5}>
              <Typography fontSize="1rem">招待した日時</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {invitees.map(invitee => (
          <ListItem key={invitee.id}>
            <Grid container>
              <Grid item xs={8} sm={5}>
                <ListItemText primary={invitee.email} />
              </Grid>
              <Grid item xs={4} sm={5}>
                <ListItemText
                  primary={invitee.invitedAt?.toLocaleString('ja-JP')}
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
