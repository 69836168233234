import { useRedirectIfSignedIn } from './hooks';
import { AuthRootWrapper } from 'components/AuthRootWrapper';
import { MaintenanceInfo } from 'components/MaintenanceInfo';
import bgImg from 'assets/loginbg.jpeg';
import { LinkToForgotPassword } from 'components/LinkToForgotPassword';
import { LinKToHelp } from 'components/LinkToHelp';
import { useAuth } from 'hooks';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

export const SignInPage = () => {
  const { signIn, status, error } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useRedirectIfSignedIn();
  return (
    <AuthRootWrapper bgImg={bgImg}>
      <Box
        sx={{ textAlign: 'center', mt: 1, mb: 5 }}
        dangerouslySetInnerHTML={{ __html: 'ポートフォリ王 &reg;' }}
      />
      <Box sx={{ mb: 2 }}>
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          variant="outlined"
          value={email}
          size="small"
          label="メールアドレスもしくは顧客番号"
          fullWidth
          type="text"
        />
      </Box>
      <TextField
        label="パスワード"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(event.target.value);
        }}
        variant="outlined"
        value={password}
        size="small"
        fullWidth
        type="password"
        autoComplete="current-password"
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={async () => {
            await signIn(email, password);
          }}
          disabled={status === 'loading'}
        >
          {status === 'loading' ? (
            <CircularProgress size={'1rem'} sx={{ color: '#fff' }} />
          ) : (
            'ログイン'
          )}
        </Button>
      </Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <MaintenanceInfo />
      <LinkToForgotPassword sx={{ mt: 4 }} />
      <LinKToHelp sx={{ mt: 1 }} />
    </AuthRootWrapper>
  );
};
