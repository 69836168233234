import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(','),
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      main: '#083e6f',
    },
    secondary: {
      main: '#0b4862',
    },
  },
});
