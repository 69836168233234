import { useAuth, useMentoringSheet, useRequireAuth } from 'hooks';
import { Loading } from 'components/Loading';
import { MentoringSheetForm } from 'components/MentoringSheetForm';
import { SelectSheet } from 'components/SelectSheet';
import { AlignCenterGrid } from 'components/AlignCenterGrid';
import { useStore } from 'contexts';

export const MentoringPage = () => {
  useRequireAuth();
  const { currentUser } = useAuth();
  const { clientInfo } = useStore()

  const [{ sheet, sheets, isLoading }, { save, edit, select }] =
    useMentoringSheet(clientInfo.data?.client?.id, true);
  if (currentUser?.uid === undefined || isLoading) {
    return <Loading />;
  }
  if (sheets.length === 0 || sheet === undefined) {
    return <div>シートがありません。担当にお問い合わせください。</div>;
  }
  return (
    <AlignCenterGrid>
      <SelectSheet
        sheets={sheets}
        sheet={sheet}
        onChange={e => select(e.target.value)}
      />
      <MentoringSheetForm
        sheet={sheet}
        edit={edit}
        save={save}
        disableMentorComment={true}
      />
    </AlignCenterGrid>
  );
};
