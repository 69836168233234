import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { ClientRow } from './ClientRow';
import { NewApiClient, ClientForClientsPage } from 'types/client';
import { Family } from 'types/family';
import { Member } from 'types/member';

interface ClientsTableProps {
  clients: ClientForClientsPage[];
  families: Family[];
  mentors: Member[];
  selectedClient: NewApiClient | null;
  onSelectionChangeRequest: (target: NewApiClient) => void;
}

export const ClientsTable = ({
  clients,
  families,
  mentors,
  selectedClient,
  onSelectionChangeRequest,
}: ClientsTableProps) => {
  const [orderBy, setOrderBy] = useState<keyof ClientForClientsPage>('name');
  const [isAsc, setIsAsc] = useState<boolean>(true);

  const handleSort = (id: keyof ClientForClientsPage) => {
    setIsAsc(orderBy === id ? !isAsc : true);
    setOrderBy(id);
  };

  const sortedClients = useMemo(() => {
    return [...clients].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      // Undefinedや空文字列を判定し、最後に来るように調整
      const aIsInvalid = aValue === undefined || aValue === '';
      const bIsInvalid = bValue === undefined || bValue === '';
      if (aIsInvalid && bIsInvalid) {
        return 0;
      } else if (aIsInvalid) {
        return 1; // Aが無効なら、Aを後ろに
      } else if (bIsInvalid) {
        return -1; // Bが無効なら、Bを後ろに
      }

      // 通常の比較
      if (aValue < bValue) {
        return isAsc ? -1 : 1;
      }
      if (aValue > bValue) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
  }, [clients, orderBy, isAsc]);

  const columns: {
    id: keyof ClientForClientsPage;
    label: string;
    width: number | 'auto';
  }[] = [
    { id: 'name', label: 'P王番号', width: 90 },
    { id: 'familyName', label: 'ファミリー', width: 110 },
    { id: 'mentorName', label: 'メンター', width: 100 },
    { id: 'clientBillingId', label: '請求先番号', width: 110 },
    { id: 'manuallyUpdatedAt', label: '銘柄最終編集日', width: 140 },
    { id: 'status', label: 'ステータス', width: 110 },
    { id: 'note', label: 'メモ', width: 'auto' },
  ];

  return (
    <TableContainer sx={{ marginTop: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={50}></TableCell>
            {columns.map(column => (
              <TableCell key={column.id} width={column.width}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={isAsc ? 'asc' : 'desc'}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedClients.map(client => {
            // const client = clientData as unknown as Client;
            return (
              <ClientRow
                key={client.id}
                client={client}
                families={families}
                mentors={mentors}
                checked={
                  selectedClient !== null && client.id === selectedClient.id
                }
                onSelectionChangeRequest={onSelectionChangeRequest}
                id={client.id}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
