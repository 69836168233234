import { Loading, ReportPresentation } from 'components';
import { useStore } from 'contexts';
import {
  useExchangeRates,
} from 'hooks';

export const AdminReportPage = () => {
  const { clientInfo } = useStore()
  const exchangeRates = useExchangeRates();
  if (clientInfo.status === 'idle' || clientInfo.status === 'loading') {
    return <Loading />;
  }
  return (
    <ReportPresentation
      clientOrFamily={clientInfo.data?.client!}
      assets={clientInfo.data?.assets ?? []}
      barData={clientInfo.data?.historicalAggregationByAssetTypeMonthly ?? []}
      exchangeRates={exchangeRates}
    />
  );
};


