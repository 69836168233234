import React, { useEffect, useReducer } from 'react';
import {
  Query,
  DocumentReference,
  onSnapshot,
  DocumentData,
  DocumentSnapshot,
  queryEqual,
  QuerySnapshot,
  FirestoreError,
  refEqual,
} from 'firebase/firestore';
import { useMemoCompare } from 'hooks';

interface IFirestoreState<D> {
  isLoading: boolean;
  data: D | undefined;
  error: FirestoreError | undefined;
}

interface IFirestoreActionTypes<D> {
  type: 'idle' | 'loading' | 'success' | 'error';
  payload?: D | FirestoreError;
}

const reducer = <T>(
  prevState: unknown,
  action: IFirestoreActionTypes<T>
): IFirestoreState<T> => {
  switch (action.type) {
    case 'idle':
      return { isLoading: true, data: undefined, error: undefined };
    case 'loading':
      return { isLoading: true, data: undefined, error: undefined };
    case 'success':
      return { isLoading: false, data: action.payload as T, error: undefined };
    case 'error':
      return {
        isLoading: false,
        data: undefined,
        error: action.payload as FirestoreError,
      };
    default:
      throw new Error('invalid action');
  }
};

// https://usehooks.com/useFirestoreQuery/
export function useFirestoreCollection<T>(query?: Query<T>) {
  const initialState: IFirestoreState<T[]> = {
    isLoading: true,
    data: undefined,
    error: undefined,
  };
  const [state, dispatch] = useReducer<
    React.Reducer<IFirestoreState<T[]>, IFirestoreActionTypes<T[]>>
  >(reducer, initialState);
  const queryCached = useMemoCompare(
    query,
    (prev, current) =>
      prev !== undefined && current !== undefined && queryEqual(current, prev)
  );
  useEffect(() => {
    if (!queryCached) {
      dispatch({ type: 'idle' });
      return;
    }
    dispatch({ type: 'loading' });
    return onSnapshot(
      queryCached,
      response => {
        const data = getCollectionData(response);
        dispatch({ type: 'success', payload: data });
      },
      error => {
        dispatch({ type: 'error', payload: error });
      }
    );
  }, [queryCached]);
  return state;
}

export const useFirestoreDocument = <T = DocumentData>(
  docRef?: DocumentReference<T>
) => {
  const initialState: IFirestoreState<T> = {
    isLoading: true,
    data: undefined,
    error: undefined,
  };
  const [state, dispatch] = useReducer<
    React.Reducer<IFirestoreState<T>, IFirestoreActionTypes<T>>
  >(reducer, initialState);
  const queryCached = useMemoCompare(
    docRef,
    (prev, current) =>
      prev !== undefined && current !== undefined && refEqual(prev, current)
  );
  useEffect(() => {
    if (!queryCached) {
      dispatch({ type: 'idle' });
      return;
    }
    dispatch({ type: 'loading' });
    return onSnapshot(
      queryCached,
      response => {
        const data = getDocData(response);
        dispatch({ type: 'success', payload: data });
      },
      error => {
        dispatch({ type: 'error', payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes
  return state;
};

// Get doc data and merge doc.id
function getDocData<T>(doc: DocumentSnapshot<T>) {
  return doc.exists() ? doc.data() : undefined;
}
// Get array of doc data from collection
function getCollectionData<T>(collection: QuerySnapshot<T>) {
  return collection.docs.map(d => d.data());
}
