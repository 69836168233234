import { useState } from 'react';
import { useAuth } from './';
import { getMembersByRole } from 'api';
import { Member } from 'types/member';

export const useMember = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');
  const { currentUser } = useAuth();

  const fetchedMembersByRole = async (role?: string) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const members = await getMembersByRole(role, token);
      setMembers(members);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  return [
    { members, error, status },
    {
      fetchedMembersByRole,
    },
  ] as const;
};
