import { useState, useEffect } from 'react';
import { useFirestoreDocument } from 'hooks';
import {
  doc,
  FieldValue,
  QueryDocumentSnapshot,
  serverTimestamp,
  SnapshotOptions,
  updateDoc,
} from 'firebase/firestore';
import { db } from 'initFirebase';

export const usePasswordResetRequest = (initialValue: string | undefined) => {
  const [clientId, setClientId] = useState<string | undefined>(initialValue);

  useEffect(() => {
    setClientId(initialValue);
  }, [initialValue]);

  const docRef = clientId
    ? doc(db, 'passwordResetRequests', clientId).withConverter(
        passwordResetRequestConverter
      )
    : undefined;
  const { data: request, isLoading } = useFirestoreDocument(docRef);

  const onUpdate = () => {
    if (docRef === undefined) {
      return;
    }
    const newData: IPasswordResetRequest = {
      isRequested: false,
      updatedAt: serverTimestamp(),
    };
    updateDoc(docRef, newData);
  };

  return { request, onUpdate, isLoading };
};

interface IPasswordResetRequest {
  isRequested: boolean;
  updatedAt?: Date | FieldValue;
}

const passwordResetRequestConverter = {
  toFirestore: (passwordResetRequest: IPasswordResetRequest) => ({
    isRequested: passwordResetRequest.isRequested,
    updatedAt: passwordResetRequest.updatedAt,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions | undefined
  ) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      isRequested: data.isRequested,
      ...(data.updatedAt && { updatedAt: data.updatedAt.toDate() }),
    } as IPasswordResetRequest;
  },
};
