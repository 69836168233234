import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

interface SnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

const createSnackbar = (severity: AlertColor) => {
  const BaseSnackbar = ({ open, onClose, message }: SnackbarProps) => (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
  return BaseSnackbar;
};

export const ErrorSnackbar = createSnackbar('error');
export const WarningSnackbar = createSnackbar('warning');
export const InfoSnackbar = createSnackbar('info');
export const SuccessSnackbar = createSnackbar('success');
