import { useAdminSignUpWithEmailAndPassword } from './hooks';
import { AuthRootWrapper } from 'components/AuthRootWrapper';
import { MaintenanceInfo } from 'components/MaintenanceInfo';
import bgImg from '../../../assets/signupbg.jpeg';
import { AuthForm } from 'components/AuthComponents';
import { useRedirectIfAdminGranted } from 'hooks/useRedirectIfAdminGranted';

export const AdminSignUpPage = () => {
  const { signUp, isLoading, email, password } =
    useAdminSignUpWithEmailAndPassword();
  useRedirectIfAdminGranted();
  return (
    <AuthRootWrapper bgImg={bgImg}>
      <AuthForm
        title="ポートフォリ王&reg; 管理者登録画面"
        buttonLabel="登録"
        isLoading={isLoading}
        email={email.value}
        password={password.value}
        handleEmailChange={email.setValue}
        handlePasswordChange={password.setValue}
        emailErrorMessage={email.errorMessage}
        passwordErrorMessage={password.errorMessage}
        onClick={signUp}
        autoComplete="new-password"
      />
      <MaintenanceInfo />
    </AuthRootWrapper>
  );
};
