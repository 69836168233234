const MAX_OLD_DATA_DATE = new Date(2023, 2 - 1, 6);

export const isOlddata = (date: Date) => {
  return date < MAX_OLD_DATA_DATE;
};

export const compatMonth = (date: Date) => {
  // x+1月(上旬)のデータをx月末扱いとする。
  const d = new Date(date);
  d.setMonth(d.getMonth() - 1);
  return d;
};
