export const formatDate = (date: any, format: string): string => {
  if (date.hasOwnProperty('_seconds')) {
    date = new Date(date._seconds * 1000);
  } else if (typeof date === 'string') {
    date = new Date(date);
  } else if (typeof date === 'number') {
    date = new Date(date);
  } // TODO: fix later. Let API return number for timestamp

  const fullYear = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const formattedString = format
    .replace('%Y', fullYear)
    .replace('%y', fullYear.slice(2))
    .replace('%m', month.padStart(2, '0'))
    .replace('%-m', month)
    .replace('%d', day.padStart(2, '0'));
  return formattedString;
};

export const japanizeDate = (date: Date) => {
  return formatDate(date, `%Y年%-m月%d日`);
};
