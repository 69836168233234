import { useCallback, useState } from 'react';
import { IAsset } from 'types/asset';
import { Order } from 'types/order';

export const useAssetTableSorter = (
  initOrder: Order = 'desc',
  initOrderBy: keyof IAsset = 'productType'
) => {
  const [order, setOrder] = useState<Order>(initOrder);
  const [orderBy, setOrderBy] = useState<keyof IAsset>(initOrderBy);

  const buildSorter = useCallback(
    (property: keyof IAsset) => {
      setOrder(prev => {
        const isAsc = orderBy === property && prev === 'asc';
        return isAsc ? 'desc' : 'asc';
      });
      setOrderBy(property);
    },
    [orderBy]
  );
  return { order, orderBy, buildSorter };
};
