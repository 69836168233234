import { AdminHeader } from 'components/AdminHeader';
import { AdminTabs } from 'components/AdminTabs';
import { useAuth } from 'hooks';
import { Outlet, useLocation } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { AdminSignInPage } from './signin';

export const AdminLayout = () => {
  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  if (currentUser === undefined) {
    return (
      <>
        <AdminHeader />
        <Loading />
      </>
    );
  }
  if (currentUser === null) {
    // not logged in
    const adminLoggedOut = /^\/admin\/(signin|signup)/i;
    if (adminLoggedOut.test(pathname)) {
      return <Outlet />; // /AdminSignin or AdminSignUp
    } //
    return <AdminSignInPage />; // fallback
  }
  const childPattern = /^\/admin\/(clients|insights|settings|families)\/?$/;
  // TODO: avoid hard coding of path. Create class RoutePath {...} in a top level file.
  if (childPattern.test(pathname)) {
    return (
      <>
        <AdminHeader />
        {/* <AccountSwitch user={user} users={users} handleChange={handleChange} /> */}
        <AdminTabs
          tabValue={pathname}
          sx={{ borderBottom: 'solid 1px #ddd', mx: 2 }}
        />
        <Outlet />
      </>
    );
  }
  // grandchildren or lower
  return (
    <>
      <AdminHeader />
      <Outlet />
    </>
  );
};
