import { useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import {
  useAuth, useRequireAdmin,

} from 'hooks';
import { useStore } from 'contexts';
import { SelectClient, Loading, AdminClientsTabs } from 'components';


export const AdminFamilyLayout = () => {
  useRequireAdmin();

  const { familyId } = useParams() as { familyId: string };
  const { clientInfo, familyInfo, selectFamily } = useStore();
  const { currentUser } = useAuth();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    selectFamily(familyId);
  }, [currentUser, familyId]);

  console.log(familyId)
  console.log(familyInfo)
  if (familyInfo.status === 'idle' || familyInfo.status === 'loading') {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{ minWidth: 120, borderBottom: 'solid 1px #ddd' }}
        display="flex"
        alignItems="center"
      >
        <IconButton onClick={() => {
          navigate('/admin/clients');
        }}>
          <ArrowBackRoundedIcon sx={{ verticalAlign: 'bottom', ml: '2px' }} />
        </IconButton>
        {familyInfo?.data?.clients &&
          <>
            <SelectClient
              clients={familyInfo.data?.clients ?? []}
              family={familyInfo?.data?.family}
            />
            <AdminClientsTabs
              sx={{ mx: 2 }}
              clientId={clientInfo.data?.client?.id ?? ''}
              currentPath={pathname}
            />
          </>
        }
      </Box >
      <Outlet />
    </>
  );
};
