import { TableRow, TableCell } from '@mui/material';
import { IAsset, TotalAsset } from 'types/asset';
import { AssetFirstColumn } from './FrozenColumnTableCell';
import { columnOrder } from './columnOrder';
import { NumberTableCell } from './NumberTableCell';

export const calcTotal = (rows: IAsset[]): TotalAsset => {
  const _calcNextValue = (
    a: number | null,
    cValue: number | null | undefined
  ) => {
    if (a === null) {
      return null;
    }
    if (cValue === null || cValue === undefined) {
      return null;
    }
    return a + cValue;
  };
  const calcTotalByField = (
    rows: IAsset[],
    fn: (asset: IAsset) => number | undefined | null
  ) => {
    const total = rows.reduce<number | null>((a, c) => {
      const cValue = fn(c);
      return _calcNextValue(a, cValue);
    }, 0);
    return total;
  };
  const marketValueDomestic = calcTotalByField(
    rows,
    c => c.marketValueDomestic
  );
  const PLDomestic = calcTotalByField(rows, c => c.PLDomestic);
  const capitalGainDomestic = calcTotalByField(
    rows,
    c => c.capitalGainDomestic
  );
  const unrealizedGainDomestic = calcTotalByField(
    rows,
    c => c.unrealizedGainDomestic
  );
  const PLInclDistributionDomestic = calcTotalByField(
    rows,
    c => c.PLInclDistributionDomestic
  );
  const distributionDomestic = calcTotalByField(
    rows,
    c => c.distributionDomestic
  );
  const total = {
    marketValueDomestic,
    PLDomestic,
    capitalGainDomestic,
    unrealizedGainDomestic,
    PLInclDistributionDomestic,
    distributionDomestic,
  };
  return total;
};

interface TotalTableRowProps {
  total: TotalAsset;
  detailVisible: boolean;
}

export const TotalTableRow = ({ total, detailVisible }: TotalTableRowProps) => (
  <TableRow
    tabIndex={-1}
    sx={{
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fafafa',
      zIndex: theme => theme.zIndex.appBar + 2,
      height: '60px',
      outline: 'solid 1px #ddd',
    }}
  >
    <AssetFirstColumn checkboxSelection={false}>
      <span style={{ fontWeight: 'bold' }}>合計</span>
    </AssetFirstColumn>
    {columnOrder
      .slice(1)
      .filter(c => !c.isDetail || detailVisible)
      .map(c =>
        c.valueType === 'number' ? (
          <NumberTableCell
            key={c.key}
            value={total[c.key] !== undefined ? total[c.key] : null}
            padding={c.padding}
            align={c.align}
            decimalPoints={
              c.decimalPoints === 'cent'
                ? 0 // never
                : c.decimalPoints
            }
          />
        ) : (
          <TableCell
            key={c.key}
            align={c.align}
            padding={c.padding}
            sx={{ whiteSpace: 'nowrap', padding: '20px' }}
          />
        )
      )}
  </TableRow>
);
