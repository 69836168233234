import React, { useState } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface AdminTabsProps extends TabsProps {
  tabValue: string;
}

export const AdminTabs = ({ tabValue, ...props }: AdminTabsProps) => {
  const [tabIx, setTabIx] = useState<string>(tabValue);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setTabIx(newValue);
    navigate(newValue);
  };
  const iconStyle = {
    fontSize: 'body1.fontSize',
    verticalAlign: 'bottom',
    mr: '3px',
  };
  // TODO: handle trailing slash (eg. /admin/settings/ that doesn't match values)
  const tabStyleForMobile = { fontSize: 11, mx: 0.5, minWidth: 0, p: 0 };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMobile ? (
        <Tabs
          value={tabIx}
          onChange={handleChange}
          {...props}
          variant="fullWidth"
        >
          <Tab
            icon={<PersonIcon />}
            label="顧客選択"
            value={'/admin/clients'}
            sx={tabStyleForMobile}
          />
          <Tab
            icon={<PeopleIcon />}
            label="ファミリー"
            value={'/admin/families'}
            sx={tabStyleForMobile}
          />
          <Tab
            icon={<AutoGraphRoundedIcon />}
            label="戦略くん(共通)"
            value={'/admin/insights'}
            sx={tabStyleForMobile}
          />
          <Tab
            icon={<SettingsRoundedIcon />}
            label="各種設定"
            value={'/admin/settings'}
            sx={tabStyleForMobile}
          />
        </Tabs>
      ) : (
        <Tabs value={tabIx} onChange={handleChange} {...props}>
          <Tab
            label={
              <div>
                <PersonIcon sx={iconStyle} />
                顧客選択
              </div>
            }
            value={'/admin/clients'}
          />
          <Tab
            label={
              <div>
                <PeopleIcon sx={iconStyle} />
                ファミリー
              </div>
            }
            value={'/admin/families'}
          />
          <Tab
            label={
              <div>
                <AutoGraphRoundedIcon sx={iconStyle} />
                戦略くん(共通)
              </div>
            }
            value={'/admin/insights'}
          />
          <Tab
            label={
              <div>
                <SettingsRoundedIcon sx={iconStyle} />
                各種設定
              </div>
            }
            value={'/admin/settings'}
          />
        </Tabs>
      )}
    </>
  );
};
