import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { FirestoreError } from 'firebase/firestore';
import { FinancialProduct } from 'types/models/financialProduct';
import { commaize } from 'utils';

type FinancialProductDetailDialogProps = {
  open: boolean;
  close: () => void;
  product: FinancialProduct | undefined;
  isLoading: boolean;
  error: FirestoreError | undefined;
};

function timestampToJPDate(date: Date | undefined | null) {
  if (!date) return;
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
}

export const FinancialProductDetailDialog = ({
  open,
  close,
  product,
  isLoading,
  error,
}: FinancialProductDetailDialogProps) => (
  <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
    <DialogTitle>個別銘柄情報</DialogTitle>
    <DialogContent>
      <SwitchPanel product={product} isLoading={isLoading} error={error} />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={close}>
        閉じる
      </Button>
    </DialogActions>
  </Dialog>
);

type SwitchPanelProps = {
  product: FinancialProduct | undefined;
  isLoading: boolean;
  error: FirestoreError | undefined;
};
const SwitchPanel = ({ product, isLoading, error }: SwitchPanelProps) => {
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <DialogContentText>{error.message}</DialogContentText>; // TODO error
  }
  if (product !== undefined) {
    return <ProductPanel product={product} />;
  }
  return <DialogContentText>予想外のエラーが発生しました。</DialogContentText>;
};

const ProductPanel = ({ product }: { product: FinancialProduct }) => (
  <Box>
    <List>
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">銘柄名</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{product?.manual_name || '-'}</Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">ISIN</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{product.isin || '-'}</Typography>
          </Grid>
        </Grid>
      </ListItem>
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">銘柄概略</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.security_short_des || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">資産クラス</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{product?.manual_class || '-'}</Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">通貨</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{product?.crncy || '-'}</Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">発行体</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{product?.issuer || '-'}</Typography>
          </Grid>
        </Grid>
      </ListItem>
      {product.manual_class !== '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">ティッカー</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.ticker || '-'}</Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">満期</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {timestampToJPDate(product.maturity) || '-'}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">初回コール日</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {timestampToJPDate(product.first_call_dt_issuance) || '-'}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">次回コール日</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {timestampToJPDate(product.nxt_call_dt) || '-'}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                満期までの残存期間(年)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.years_to_maturity || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                初回コール日までの残存年数
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product.years_to_first_call || '-'}</Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                次回コール日までの残存年数
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product.years_to_next_call || '-'}</Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">クーポン</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product.cpn || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">利払周期</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.cpn_freq || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">前回利払日</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {timestampToJPDate(product.prev_cpn_dt) || '-'}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">次回利払日</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {timestampToJPDate(product?.nxt_cpn_dt) || '-'}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">経過利息</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.int_acc || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                バックエンドスプレッド
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.flt_spread || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class !== 'ファンド' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">直近価格</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.px_latest_source || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">基準日</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              {timestampToJPDate(product.evaluatedAt) || '-'}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">基準日価格</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{product?.px_rd_source || '-'} </Typography>
          </Grid>
        </Grid>
      </ListItem>
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">格付け（S&P）</Typography>
            </Grid>
            <Grid item xs={!2} md={6}>
              <Typography>{product?.rtg_sp || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">格付け（Moody's）</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.rtg_moody || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">格付け（FITCH）</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.rtg_fitch || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '株式' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">PER</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.pe_ratio || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '株式' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">PBR</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.px_to_book_ratio || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '株式' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">PSR</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.px_to_sales_ratio || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '株式' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">企業価値（EBITDA）</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {product?.current_ev_to_t12m_ebitda || '-'}{' '}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '株式' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">直近時価総額</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {product?.cur_mkt_cap ? commaize(product?.cur_mkt_cap) : '-'}{' '}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '株式' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">予想配当利回り</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.eqy_dvd_yld_est || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === 'ファンド' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">企業websiteリンク</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {product.company_web_address !== null ? (
                <Link
                  href={'https://' + product?.company_web_address}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {product.company_web_address}
                </Link>
              ) : (
                '-'
              )}
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                初回コール利回り(基準日/ソース指定価格)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.first_call_yield || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {product.manual_class === '債券' && (
        <ListItem divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                満期利回り(基準日/ソース指定価格)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.maturity_yield || '-'} </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </List>
  </Box>
);
