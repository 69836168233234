import React from 'react';
import { Box, Typography } from '@mui/material';

interface IDashboardContentBox {
  title: string;
  icon: React.ReactNode;
  color: string;
}

export const DashboardContentBox: React.FC<IDashboardContentBox> = ({
  title,
  icon,
  color,
  children,
}) => (
  <Box
    sx={{
      boxShadow: 2,
      px: 5,
      py: 2,
      borderRadius: '6px',
      borderLeft: `5px solid ${color}`,
      backgroundColor: '#fff',
    }}
  >
    <Box display="flex" alignItems="center">
      {icon}
      <Typography
        sx={{ fontSize: '1rem', fontWeight: 'bold', color: '#0b4862', ml: 0.5 }}
      >
        {title}
      </Typography>
    </Box>
    <Box sx={{ my: 1, whiteSpace: 'pre-wrap' }}>{children}</Box>
  </Box>
);
