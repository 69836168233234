import { useState } from 'react';
import { IAsset, IManualInput } from 'types/asset';

import { useAuth } from './';
import {
  getAssetsByClientId,
  getAssetsByFamilyId,
  putAsset,
  deleteAsset,
  postAsset,
} from 'api';

export const useAssets = () => {
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');
  const { currentUser } = useAuth();

  const fetchAssetsByClientId = async ({ clientId }: { clientId: string }) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const assets = await getAssetsByClientId({ clientId, token });
      setAssets(assets);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    } finally {
    }
  };

  const fetchAssetsByFamilyId = async ({ familyId }: { familyId: string }) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const assets = await getAssetsByFamilyId({ id: familyId, token });
      setAssets(assets);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const fetchAssetById = async ({
    clientId,
    id,
  }: {
    clientId: string;
    id: string;
  }) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      await fetchAssetById({ clientId, id: token });
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const createAsset = async ({
    clientId,
    data,
  }: {
    clientId: string;
    data: IManualInput;
  }) => {
    setStatus('loading');
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const newAsset = await postAsset({ clientId, data, token });
      setAssets(assets => [newAsset, ...assets]);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const replaceAsset = async ({
    clientId,
    id,
    data,
  }: {
    clientId: string;
    id: string;
    data: IManualInput;
  }) => {
    setStatus('loading');
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const newData = await putAsset({ clientId, id, data, token });
      const idx = assets.findIndex(asset => asset.id === id);

      setAssets(assets => [
        ...assets.slice(0, idx),
        newData,
        ...assets.slice(idx + 1),
      ]);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const removeAsset = async ({
    clientId,
    id,
  }: {
    clientId: string;
    id: string;
  }) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      await deleteAsset({ clientId, id, token });
      const idx = assets.findIndex(a => a.id === id);
      setAssets(assets => [...assets.slice(0, idx), ...assets.slice(idx + 1)]);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  return [
    { assets, status, error },
    {
      fetchAssetsByClientId,
      fetchAssetsByFamilyId,
      fetchAssetById,
      createAsset,
      replaceAsset,
      removeAsset,
    },
  ] as const;
};
