import { Box, Button, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useInsightSheet } from 'hooks';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

export const AdminClientInsightPage = () => {
  const { clientId } = useParams();
  const { insight, newInsightIframe, onChangeNewIframe, save } =
    useInsightSheet(clientId || '');
  return (
    <Box sx={{ px: 4, pt: 4, pb: 8 }}>
      <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
        顧客別投資戦略スライド
      </Typography>
      <Typography component="p" variant="caption" sx={{ mb: 2 }}>
        このページでは、顧客個別に表示する戦略くんを編集できます
        (顧客共通の投資戦略スライドは
        <Link component={RouterLink} to="/admin/insights">
          こちら
        </Link>
        から編集できます)。
      </Typography>
      <TextField
        size="small"
        value={newInsightIframe || ''}
        label="iframe"
        placeholder='<iframe class="speakerdeck-iframe" frameborder="0" src="https:/...></iframe>'
        onChange={e => onChangeNewIframe(e.target.value)}
        fullWidth
      />
      <Box sx={{ mt: 4 }}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={save}
        >
          変更を保存する
        </Button>
      </Box>
    </Box>
  );
};
