import { TableBody } from '@mui/material';

import { AssetTableRow } from './AssetTableRow';
import { IAsset } from 'types/asset';
import { TotalTableRow, calcTotal } from './TotalTableRow';
import { Order } from 'types/order';
import { stableSort } from './stableSort';
import { getComparator } from './getComparator';

interface AssetTableBodyProps {
  assets: IAsset[];
  order: Order;
  orderBy: keyof IAsset;
  checkboxSelection: boolean;
  selectedAssets?: IAsset[];
  onSelectAsset?: (asset: IAsset) => void;
  onClickProductName?: (bbgDocId: string) => void;
  detailVisible: boolean;
  soldVisible: boolean;
}

export const AssetTableBody = ({
  assets,
  order,
  orderBy,
  selectedAssets,
  checkboxSelection,
  onSelectAsset,
  onClickProductName,
  detailVisible,
  soldVisible,
}: AssetTableBodyProps) => (
  <TableBody>
    {stableSort<IAsset>(assets, getComparator(order, orderBy))
      .filter(asset => soldVisible || asset.amount > 0)
      .map(asset => (
        <AssetTableRow
          key={asset.id}
          asset={asset}
          checkboxSelection={checkboxSelection}
          checked={selectedAssets?.some(sa => sa.id === asset.id)}
          onSelectAsset={onSelectAsset}
          onClickProductName={onClickProductName}
          detailVisible={detailVisible}
        />
      ))}
    <TotalTableRow
      key={'total'}
      total={calcTotal(assets)}
      detailVisible={detailVisible}
    />
  </TableBody>
);
