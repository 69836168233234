import { styled } from '@mui/material/styles';
import { commaize } from 'utils/commaize';
import { Grid } from '@mui/material';

const DL = styled('dl')(({ theme }) => ({
  marginTop: 10,
  maxWidth: 355,
}));

const DT = styled('dt')(({ theme }) => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  paddingBottom: theme.spacing(0),
  '&:after': {
    content: '": "',
  },
}));

const DD = styled('dd')(({ theme }) => ({
  textAlign: 'right',
  whiteSpace: 'nowrap',
  paddingBottom: theme.spacing(2),
  '&:after': {
    content: '"円"',
    paddingLeft: theme.spacing(0.5),
  },
  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
    marginBottom: 1,
  },
}));

interface SummaryProps {
  total: {
    marketValueDomestic: number | null;
    PLDomestic: number | null;
    capitalGainDomestic: number | null;
    unrealizedGainDomestic: number | null;
    PLInclDistributionDomestic: number | null;
    distributionDomestic: number | null;
  };
}

export const Summary = ({ total }: SummaryProps) => (
  <Grid container component={DL} rowSpacing={1}>
    {/* {tableData.map((row) => ( */}
    {/* <React.Fragment key={row.title}> */}
    <Grid item xs={6} component={DT}>
      評価額合計
    </Grid>
    <Grid item xs={6} component={DD}>
      {total.marketValueDomestic !== null
        ? commaize(total.marketValueDomestic)
        : '-'}
    </Grid>
    <Grid item xs={6} component={DT}>
      利金配当金込み評価損益
    </Grid>
    <Grid item xs={6} component={DD}>
      {total.PLInclDistributionDomestic !== null
        ? commaize(total.PLInclDistributionDomestic)
        : '-'}
    </Grid>
    <Grid item xs={6} component={DT}>
      受取済利金配当金
    </Grid>
    <Grid item xs={6} component={DD}>
      {total.distributionDomestic !== null
        ? commaize(total.distributionDomestic)
        : '-'}
    </Grid>
    <Grid item xs={6} component={DT}>
      売買損益円換算
    </Grid>
    <Grid item xs={6} component={DD}>
      {total.capitalGainDomestic !== null
        ? commaize(total.capitalGainDomestic)
        : '-'}
    </Grid>
    <Grid item xs={6} component={DT}>
      含み損益円換算
    </Grid>
    <Grid item xs={6} component={DD}>
      {total.unrealizedGainDomestic !== null
        ? commaize(total.unrealizedGainDomestic)
        : '-'}
    </Grid>
  </Grid>
);
