import React, { useState, useCallback, useEffect } from 'react';
import ExampleTheme from './themes/Theme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
// import TreeViewPlugin from "./plugins/TreeViewPlugin"; For debugging
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import './richtext_styles.css';

function Placeholder() {
  return <div className="editor-placeholder"></div>;
}

function EditorContent({ onChange }) {
  function handleEditorChange(editorState) {
    const editorStateJSON = editorState.toJSON();
    onChange(JSON.stringify(editorStateJSON));
  }
  return (
    <div className="editor-container">
      <ToolbarPlugin />
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        {/* <TreeViewPlugin /> For debugging */}
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <OnChangePlugin onChange={handleEditorChange} />
      </div>
    </div>
  );
}

export default function RichTextEditor({ mtgMemoLexical, onChange }) {
  const initConfig = {
    theme: ExampleTheme,
    editorState: mtgMemoLexical,
    onError(error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  return (
    <LexicalComposer initialConfig={initConfig}>
      <EditorContent mtgMemoLexical={mtgMemoLexical} onChange={onChange} />
    </LexicalComposer>
  );
}
