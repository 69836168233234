import { useState } from 'react';
import { useAuth } from './';
import { getFamilies, getFamilyById, postFamily, putFamilyById } from 'api';
import { Family } from 'types/family';

export const useFamily = () => {
  const [families, setFamilies] = useState<Family[]>([]);
  const [family, setFamily] = useState<Family>();
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');
  const { currentUser } = useAuth();

  const fetchedFamilies = async () => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const families = await getFamilies(token);
      setFamilies(families);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const fetchedFamilyById = async ({ id }: { id: string }) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const family = await getFamilyById({ id, token });
      setFamily(family);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const createdFamily = async (data: { name: string }) => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const newFamily = await postFamily({ data, token });
      setFamily(newFamily);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const replacedFamilyById = async (id: string, data: { name: string }) => {
    setStatus('loading');
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const newData = await putFamilyById({ id, data, token });
      setFamily(newData);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
      throw e;
    }
  };

  return [
    { families, family, error, status },
    {
      fetchedFamilies,
      fetchedFamilyById,
      createdFamily,
      replacedFamilyById,
    },
  ] as const;
};
