import { Box } from '@mui/material';
import { useMaintenance } from 'hooks/maintenance';

export const MaintenanceInfo = () => {
  const maintenanceInfo = useMaintenance();
  return (
    <Box sx={{ mt: 3, color: '#444' }}>
      {maintenanceInfo && (
        <>
          <div style={{ fontWeight: 'bold' }}>メンテナンス情報</div>
          <Box>{maintenanceInfo}</Box>
        </>
      )}
    </Box>
  );
};
