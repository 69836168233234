import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { CustomTooltip } from './CustomTooltip';
import { getHSLValue } from './getHSLValue';
import { CustomLegend } from './CustomLegend';
import {
  makeXLabel,
} from './helper';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ClientAssetHistoryAggregation } from 'types';

// TODO:  Handle aggregatedBy === currency or brokerageAccount

/**
 * Stacked bar chart component that displays data in a chart format.
 * Always show them in 1M Yen.
 * @param {T[]} data - Array of data to be displayed in the chart
 * @return {JSX.Element} React element representing the stacked bar chart
 */
export const StackedBarChart = <K extends string, T extends ClientAssetHistoryAggregation<K>>({
  data,
}: { data: T[] }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const keySet = new Set<K>();
  data.forEach(d => {
    Object.keys(d.metrics).forEach((k) => keySet.add(k as K));
  });
  const uniqueKeys = Array.from(keySet);

  return (
    <>
      <Typography fontSize={16}>単位: 百万円</Typography>
      <ResponsiveContainer
        width={isMobile ? '100%' : '90%'}
        height={400}
      >
        <BarChart
          width={500}
          height={500}
          data={data.map(record => ({
            evaluatedAt: (new Date(record.evaluatedAt)).getTime(),
            total: record.total,
            ...record.metrics
          }))}
          margin={{
            top: 30,
            left: isMobile ? -30 : 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="evaluatedAt"
            stroke="#000"
            tickFormatter={timestamp => makeXLabel(timestamp)}
            interval={0}
            domain={data.length === 0 ? [] : ['dataMin', 'dataMax']} // hack to fix bug https://github.com/recharts/recharts/issues/2593
          ></XAxis>
          <YAxis
            stroke="#000"
            tickFormatter={value => Math.floor(value / 1e6).toLocaleString()} // convert unit into million
            domain={[
              0,
              (dataMax: number) => dataMax * 1.1,  //TODO: これでよいのか要検証。1.1倍ぐらいで十分？1.1はマジックナンバー。
            ]}
          ></YAxis>
          <Tooltip offset={50} content={<CustomTooltip />} />
          <Legend
            align="left"
            iconSize={16}
            wrapperStyle={{
              marginLeft: isMobile ? '8px' : '40px',
              paddingTop: '10px',
            }}
            content={<CustomLegend />}
          />
          {uniqueKeys.map((k, i) => (
            <Bar
              key={k}
              dataKey={k}
              stackId="_" // everything is in the same stack
              maxBarSize={60}
              fill={getHSLValue(i)}
              name={({
                equity: "株式",
                bond: "債券",
                alternative: "オルタナティブ",
                cache: "キャッシュ",
                etf: "ETF",
                investmentTrust: "投資信託",
                fund: "ファンド",
                realEstate: "不動産",
                otherProductType: "その他",
              } as Record<K, string>)[k]}  // TODO: Pass K and corresponding names from props.
            >
              {i === uniqueKeys.length - 1 && <LabelList
                dataKey="total"
                position="top"
                // formatter={(value: number)=> commaize(Math.round(value / 1e6))}  // 123, 12, 1.1
                formatter={(value: number) => Math.floor(value / 1000000).toLocaleString()}
                style={{ fontSize: 12 }}
              />
              }
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer >
    </>
  );
};
