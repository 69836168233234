import { useState } from 'react';

import { useFirestoreCollection, useTextField } from 'hooks';
import { AuthError, createUserWithEmailAndPassword } from 'firebase/auth';
import { handleSignUpErrorCode } from 'utils/handleAuthErrorCode';
import { auth, functions } from 'initFirebase';
import { useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';

const checkIfEmailIsInvitedAsAdmin = httpsCallable<
  { email: string },
  { isInvitedAsAdmin: boolean }
>(functions, 'checkIfEmailIsInvitedAsAdmin');

export const useAdminSignUpWithEmailAndPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const queryEmail = searchParams.get('email');
  const emailField = useTextField(queryEmail || '');
  const passwordField = useTextField('');

  const signUp = async (email: string, password: string) => {
    setIsLoading(true);
    const result = await checkIfEmailIsInvitedAsAdmin({ email: email });
    const { isInvitedAsAdmin } = result.data;
    if (!isInvitedAsAdmin) {
      emailField.setErrorMessage('招待されていないメールアドレスです。');
      setIsLoading(false);
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
      const authError = err as AuthError;
      const { emailErrorMessage, passwordErrorMessage } = handleSignUpErrorCode(
        authError.code
      );
      if (emailErrorMessage.length > 0) {
        emailField.setErrorMessage(emailErrorMessage);
      }
      if (passwordErrorMessage.length > 0) {
        passwordField.setErrorMessage(passwordErrorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    signUp,
    isLoading,
    email: emailField,
    password: passwordField,
  };
};
