import { useState } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Family } from 'types/family';

interface EditFamilyDialogProps {
  open: boolean;
  onClose: () => void;
  family: Family;
  onSave: (formFamily: Family) => Promise<void>;
  status: 'loading' | 'idle' | 'succeeded' | 'failed';
  error: string | null;
}

export const EditFamilyDialog = ({
  open,
  onClose,
  family,
  onSave,
  status,
  error,
}: EditFamilyDialogProps) => {
  const [formFamily, setFormFamily] = useState(family);
  const handleSave = async () => {
    try {
      await onSave(formFamily);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>ファミリー名編集</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <div>
          <TextField
            type="text"
            label="ファミリー名"
            value={formFamily.name}
            autoFocus
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event =>
              setFormFamily(prev => ({ ...prev, name: event.target.value }))
            }
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ mr: 4, mb: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSave}>
          {status === 'loading' ? '更新中...' : '編集する'}
        </Button>
      </DialogActions>
      {status === 'failed' && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          編集できませんでした
        </div>
      )}
    </Dialog>
  );
};
