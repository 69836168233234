import { useCallback, useState } from 'react';

export const useSingleSelect = <T extends {}>(
  compare?: (a: T, b: T) => boolean
) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const select = useCallback((item: T) => {
    setSelectedItems(prevSelectedItems => {
      console.assert(
        prevSelectedItems.length < 2,
        'useSelect allows selecting up to 1 item.'
      );
      if (prevSelectedItems.length === 0) {
        return [item];
      }
      const [prevItem] = prevSelectedItems;
      const isEqual =
        compare === undefined ? prevItem === item : compare(prevItem, item);
      if (isEqual) {
        return [];
      }
      return [item];
    });
  }, []);

  const unselect = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const getItem = () => {
    return selectedItems.at(0);
  };
  const action = {
    select,
    unselect,
    getItem,
  };
  return [selectedItems, action] as const;
};
