import { useState } from 'react';
import { useAuth } from './';
import { getClients, putClientById } from 'api';
import { DraftClient, NewApiClient } from 'types/client';

export const useClientNew = () => {
  const [clients, setClients] = useState<NewApiClient[]>([]);
  const [client, setClient] = useState<NewApiClient>();
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');
  const { currentUser } = useAuth();

  const fetchedClients = async () => {
    setStatus('loading');
    setError(null);
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const clients = await getClients(token);
      setClients(clients);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
    }
  };

  const replacedClientById = async (id: string, data: DraftClient) => {
    setStatus('loading');
    const token = (await currentUser?.getIdToken()) ?? '';
    try {
      const newData = await putClientById({ id, data, token });
      setClient(newData);
      setStatus('succeeded');
    } catch (e) {
      setStatus('failed');
      if (e instanceof Error) {
        setError(e.message);
      }
      console.error(e);
      throw e;
    }
  };

  const numbers = (clients ?? [{ name: '0' }]).map(client => {
    const match = client.name.match(/^P?(\d{4})$/);
    return match ? parseInt(match[1], 10) : 0;
  });
  const maxPortpholiohIdNumber = Math.max(...numbers);

  return [
    { clients, error, status, maxPortpholiohIdNumber },
    {
      fetchedClients,
      replacedClientById,
    },
  ] as const;
};
