import { useRef } from 'react';

// https://usehooks.com/useMemoCompare/
export function useMemoCompare<T>(
  state: T,
  compare: (previous: T, current: T) => boolean
) {
  const previousRef = useRef<T>();
  const previous = previousRef.current;
  const isEqual = previous !== undefined && compare(previous, state);
  if (!isEqual) {
    previousRef.current = state;
  }
  return isEqual ? previous : state;
}
