import React from 'react';
import {
  IAsset,
  ICurrency,
  IProductTypeData,
  IBrokerageAccountData,
} from 'types/asset';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { commaize } from 'utils';
import { IChartData } from 'types/asset';

interface AggregationTableProps {
  data: IChartData[];
  title: string;
  labelName: string;
}

const TableHeadCell = styled(TableCell)({
  fontWeight: 'bold',
  width: '50%',
});

export const AggregationTable = ({
  title,
  labelName,
  data,
}: AggregationTableProps) => (
  <>
    <Typography variant="subtitle2" gutterBottom>
      {title}
    </Typography>
    <TableContainer sx={{ maxWidth: 300 }}>
      <Table size="small">
        <TableHead sx={{ backgroundColor: '#FAFAFA' }}>
          <TableRow>
            <TableHeadCell align="center">{labelName}</TableHeadCell>
            <TableHeadCell align="center">評価額</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.label}>
              <TableCell align="left" sx={{ backgroundColor: '#fafafa' }}>
                {row.label}
              </TableCell>
              <TableCell align="right">
                {commaize(row.value / 1000000)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
