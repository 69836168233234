import { TableBody } from '@mui/material';
import { AssetTableRow } from './AssetTableRow';
import { IAsset } from 'types/asset';
import { TotalTableRow, calcTotal } from './TotalTableRow';
import { Order } from 'types/order';
import { stableSort } from '../AssetTable/stableSort';
import { getComparator } from '../AssetTable/getComparator';

interface AssetTableBodyProps {
  assets: IAsset[];
  order: Order;
  orderBy: keyof IAsset;
  checkboxSelection: boolean;
  selectedAssets?: IAsset[];
  onSelectAsset?: (asset: IAsset) => void;
  onClickProductName?: (bbgDocId: string) => void;
  detailVisible: boolean;
  soldVisible: boolean;
}

export const AssetTableBody = ({
  assets,
  order,
  orderBy,
  selectedAssets,
  checkboxSelection,
  onSelectAsset,
  onClickProductName,
  detailVisible,
  soldVisible,
}: AssetTableBodyProps) => (
  <>
    <TableBody>
      {stableSort<IAsset>(assets, getComparator(order, orderBy))
        .filter(asset => soldVisible || asset.amount > 0)
        .map(asset => (
          <AssetTableRow
            key={asset.id}
            asset={asset}
            checkboxSelection={checkboxSelection}
            checked={selectedAssets?.some(sa => sa.id === asset.id)}
            onSelectAsset={onSelectAsset}
            onClickProductName={onClickProductName}
            detailVisible={detailVisible}
          />
        ))}
    </TableBody>
    <tfoot
      style={{
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fafafa',
      }}
    >
      <TotalTableRow
        key={'total'}
        total={calcTotal(assets)}
        detailVisible={detailVisible}
      />
    </tfoot>
  </>
);
