import { useState, useEffect } from 'react';
import { FamiliesTable } from './FamiliesTable';
import { useFamily, useToggle } from 'hooks';
import { Box, IconButton, Tooltip } from '@mui/material';
import { EditFamilyDialog } from './EditFamilyDialog';
import EditIcon from '@mui/icons-material/Edit';
import { Loading } from 'components';
import { Family } from 'types/family';

export const FamiliesPage = () => {
  const [{ families, status, error }, { fetchedFamilies, replacedFamilyById }] =
    useFamily();
  const [selectedFamily, setSelectedFamily] = useState<Family | null>(null);
  const [isOpenEditDialog, toggleIsOpenEditDialog] = useToggle(false);

  useEffect(() => {
    if (status === 'idle') {
      fetchedFamilies();
    }
  }, []);

  const handleSelectionChange = (family: Family) => {
    if (selectedFamily && family.id === selectedFamily.id) {
      setSelectedFamily(null); // すでに選択されている家族を選択した場合、選択を解除する
    } else {
      setSelectedFamily(family); // 新しく選択された家族を設定する
    }
  };

  const handleOpenEditDialog = (family: Family) => {
    setSelectedFamily(family);
    toggleIsOpenEditDialog();
  };

  const handleCloseEditDialog = () => {
    setSelectedFamily(null);
    toggleIsOpenEditDialog();
  };

  const handleEdit = async (formFamily: Family) => {
    if (selectedFamily) {
      try {
        await replacedFamilyById(selectedFamily.id, formFamily);
        fetchedFamilies();
      } catch (e) {
        console.log(error);
      }
    } else {
      console.error('No family selected.');
    }
  };

  return (
    <Box sx={{ px: 4, py: 1 }}>
      <div style={{ height: '20px' }}>
        {selectedFamily && (
          <Tooltip title="顧客を編集">
            <IconButton
              aria-label="download"
              onClick={() => handleOpenEditDialog(selectedFamily)}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {status === 'loading' ? (
        <Loading />
      ) : status === 'failed' ? (
        error
      ) : (
        <FamiliesTable
          families={families ?? []}
          selectedFamily={selectedFamily}
          onSelectionChangeRequest={handleSelectionChange}
        />
      )}
      {isOpenEditDialog && selectedFamily && (
        <EditFamilyDialog
          open={isOpenEditDialog}
          onClose={handleCloseEditDialog}
          family={selectedFamily}
          onSave={handleEdit}
          status={status}
          error={error}
        />
      )}
    </Box>
  );
};
