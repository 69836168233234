import {
  DeprecatedMentoringsheet,
  MentoringSheet,
  NewMentoringSheetForm,
} from 'types';
import { useSnackbar } from 'hooks';
import { useState, useEffect, useReducer } from 'react';
import {
  MENTORING_SHEETS_ACTION_TYPE,
  mentoringSheetReducer,
} from './mentoringSheetReducer';
import {
  createMentoringsheet,
  deleteMentoringsheet,
  listMentoringsheets,
  updateMentoringsheet,
} from 'api/mentoringsheet';

const initialState = {
  currentId: null,
  sheets: [],
};

export const useMentoringSheet = (
  clientId: string | undefined,
  onlyPublished: boolean
) => {
  // clientId can be undefined when loading
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [state, dispatch] = useReducer(mentoringSheetReducer, initialState);

  const sheet = state.sheets.find(s => s.id === state.currentId);
  const sheets = state.sheets;

  useEffect(() => {
    if (clientId === undefined) {
      setIsLoading(false);
      setError(undefined);
      dispatch({
        type: MENTORING_SHEETS_ACTION_TYPE.LIST,
        sheets: [],
      });
      return;
    }

    setIsLoading(true);
    listMentoringsheets(clientId, onlyPublished)
      .then(sheets => {
        dispatch({
          type: MENTORING_SHEETS_ACTION_TYPE.LIST,
          sheets: sheets,
        });
      })
      .catch(err => {
        setError(err as Error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId, onlyPublished]);

  const create = async (meetingDate: Date) => {
    if (clientId === undefined) {
      throw new Error('clientId is undefined');
    }
    const newSheetForm: NewMentoringSheetForm = {
      clientId: clientId,
      createdAt: new Date(),
      nextActions: '',
      mentorComment: '',
      netWorthGoal: sheet?.netWorthGoal ?? 0,
      financialGoal: sheet?.financialGoal ?? '',
      result: '',
      review: '',
      todo: sheet?.nextActions ?? '',
      meetingDate: meetingDate,
      isDraft: true,
      mtgMemo: '',
      mtgMemoLexical: '',
    };
    try {
      const ref = await createMentoringsheet(newSheetForm);
      const newSheet: MentoringSheet = {
        ...newSheetForm,
        id: ref.id,
      };
      dispatch({ type: MENTORING_SHEETS_ACTION_TYPE.CREATE, sheet: newSheet });
      showSuccessSnackbar('新しいGPOPシートを追加しました');
    } catch (e) {
      showErrorSnackbar('GPOPシートの追加に失敗しました。');
    }
  };

  const edit = (fracSheet: Partial<MentoringSheet>) => {
    dispatch({ type: MENTORING_SHEETS_ACTION_TYPE.EDIT, fracSheet: fracSheet });
  };

  const save = async () => {
    if (sheet === undefined) {
      throw new Error('sheet is undefined');
    }
    try {
      await updateMentoringsheet(sheet);
      showSuccessSnackbar('シートを保存しました。');
    } catch (e) {
      showErrorSnackbar('シートの保存に失敗しました。');
      throw e;
    }
  };

  const publish = async () => {
    if (sheet === undefined) {
      throw new Error('sheet is undefined');
    }
    try {
      await updateMentoringsheet({ ...sheet, ...{ isDraft: false } });
      dispatch({
        type: MENTORING_SHEETS_ACTION_TYPE.EDIT,
        fracSheet: { isDraft: false },
      });
      showSuccessSnackbar('お客様に公開しました。');
    } catch (e) {
      showErrorSnackbar('シートの公開に失敗しました。');
      throw e;
    }
  };

  const select = (id: string) => {
    dispatch({ type: MENTORING_SHEETS_ACTION_TYPE.SElECT, id: id });
  };

  const deleteSheet = async () => {
    if (state.currentId === null) {
      throw new Error('No sheet is selected');
    }
    try {
      await deleteMentoringsheet(state.currentId);
      dispatch({
        type: MENTORING_SHEETS_ACTION_TYPE.DELETE,
      });
    } catch (e) {
      throw e;
    }
  };

  return [
    { sheet, sheets, isLoading, error },
    { create, edit, select, save, publish, deleteSheet },
  ] as const;
};

export const isDeprecatedSheet = (
  sheet: MentoringSheet
): sheet is DeprecatedMentoringsheet => {
  return (sheet as DeprecatedMentoringsheet).title !== undefined;
};
