import { useEffect, useMemo, useState } from 'react';
import {
  useRequireAdmin,
  useToggle,
  useFamily,
  useMember,
  useClientNew,
} from 'hooks';
import { ClientsMenu } from './ClientsMenu';
import { ClientsTable } from './ClientsTable';
import { AddClientDialog } from './AddClientDialog';
import { Loading } from 'components/Loading';
import { useFamilyOptions } from './hooks';
import { NewApiClient, ClientForClientsPage, DraftClient } from 'types/client';
import { EditClientDialog } from './EditClientDialog';
import { Box } from '@mui/material';
import { useDownloadExcel } from 'hooks';
import { DeleteClientDialog } from './DeleteClientDialog';

export const ClientsPage = () => {
  const [
    {
      clients,
      status: clientsStatus,
      error: clientsError,
      maxPortpholiohIdNumber,
    },
    { fetchedClients, replacedClientById },
  ] = useClientNew();
  const [
    { families, status: familyStatus, error: familyError },
    { fetchedFamilies },
  ] = useFamily();
  const [
    { members: mentors, status: memberStatus, error: memberError },
    { fetchedMembersByRole },
  ] = useMember();
  const [selectedClient, setSelectedClient] = useState<NewApiClient | null>(
    null
  );
  const [isOpenEditDialog, toggleIsOpenEditDialog] = useToggle(false);
  const [isOpenCreateDialog, toggleIsOpenCreateDialog] = useToggle(false);
  const [isOpenDeleteDialog, toggleIsOpenDeleteDialog] = useToggle(false);
  const { familyOptions } = useFamilyOptions();
  const [downloadExcelState, { downloadExcelForAllClients }] =
    useDownloadExcel();
  const [searchClientQuery, setSearchClientQuery] = useState('');
  const handleSearchClientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchClientQuery(event.target.value);
  };

  useRequireAdmin();

  const clientsForClientsPage = useMemo(() => {
    return clients?.map(
      (client: NewApiClient): ClientForClientsPage => ({
        ...client,
        familyName:
          families.find(family => family.id === client.familyId)?.name || '',
        mentorName:
          mentors.find(mentor => mentor.id === client.mentoredBy)?.fullName ||
          '',
      })
    );
  }, [clients, families, mentors]);

  useEffect(() => {
    if (clientsStatus === 'idle') {
      fetchedClients();
    }
  }, [clientsStatus, fetchedClients]);

  useEffect(() => {
    if (familyStatus === 'idle') {
      fetchedFamilies();
    }
  }, [familyStatus, fetchedFamilies]);

  useEffect(() => {
    if (memberStatus === 'idle') {
      fetchedMembersByRole('mentor');
    }
  }, [fetchedMembersByRole, memberStatus]);

  if (
    clientsStatus === 'loading' ||
    clients === undefined ||
    familyStatus === 'loading'
  ) {
    return <Loading />;
  }

  const handleEdit = async (formState: DraftClient) => {
    if (selectedClient) {
      try {
        await replacedClientById(selectedClient.id, formState);
        fetchedClients();
      } catch (e) {
        console.log('Error updating client:', e);
        throw e;
      }
    } else {
      console.log('No client selected.');
    }
  };

  const handleCloseCreateDialog = () => {
    setSelectedClient(null);
    toggleIsOpenCreateDialog();
  };

  const handleCloseEditDialog = () => {
    setSelectedClient(null);
    toggleIsOpenEditDialog();
  };

  const handleCloseDeleteDialog = () => {
    setSelectedClient(null);
    toggleIsOpenDeleteDialog();
  };

  return (
    <Box sx={{ px: 4, py: 1 }}>
      <ClientsMenu
        downloadExcel={downloadExcelForAllClients}
        toggleAddClientDialog={toggleIsOpenCreateDialog}
        toggleEditClientDialog={toggleIsOpenEditDialog}
        toggleDeleteClientDialog={toggleIsOpenDeleteDialog}
        isClientSelected={selectedClient !== null}
        downloadExcelStatus={downloadExcelState.status}
        downloadExcelErrorMessage={downloadExcelState.errorMessage}
        clientSearchQuery={searchClientQuery}
        onChangeClientSearchQuery={handleSearchClientChange}
      />
      <ClientsTable
        clients={(clientsForClientsPage ?? []).filter(
          client =>
            client.name
              .toLocaleLowerCase()
              .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
            (client.familyName ?? '')
              .toLocaleLowerCase()
              .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
            (client.note ?? '')
              .toLocaleLowerCase()
              .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
            (client.clientBillingId ?? '')
              .toLocaleLowerCase()
              .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
            (client.mentorName ?? '')
              .toLocaleLowerCase()
              .includes(searchClientQuery.trim().toLocaleLowerCase())
        )}
        families={families}
        mentors={mentors}
        selectedClient={selectedClient}
        onSelectionChangeRequest={target => {
          setSelectedClient(current =>
            !current || current.id !== target.id ? target : null
          );
        }}
      />
      {familyOptions !== undefined && (
        <AddClientDialog
          key={maxPortpholiohIdNumber}
          open={isOpenCreateDialog}
          onClose={handleCloseCreateDialog}
          familyOptions={familyOptions}
          maxPortpholiohIdNumber={maxPortpholiohIdNumber}
          fetchedClients={fetchedClients}
        />
      )}
      {selectedClient && familyOptions !== undefined && (
        <EditClientDialog
          open={isOpenEditDialog}
          onClose={handleCloseEditDialog}
          client={selectedClient}
          families={families}
          mentors={mentors}
          onSave={handleEdit}
          status={clientsStatus}
          error={clientsError}
          key={selectedClient.id}
        />
      )}
      {selectedClient && (
        <DeleteClientDialog
          open={isOpenDeleteDialog}
          onClose={handleCloseDeleteDialog}
          client={selectedClient}
        />
      )}
    </Box>
  );
};
