import React, { useEffect } from 'react';
import { useAuth, useClientV0, usePasswordResetRequest } from 'hooks';
import { useNavigate } from 'react-router-dom';

export const useRedirectIfSignedIn = (
  updatePasswordPath = '/update-password'
) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { request, isLoading } = usePasswordResetRequest(currentUser?.uid);

  useEffect(() => {
    if (currentUser === undefined || currentUser === null) {
      return;
    }
    if (isLoading) {
      return;
    }
    const redirectPath = '/clients/' + currentUser.uid;
    navigate(redirectPath);
  }, [currentUser, navigate, request, isLoading]);
};
