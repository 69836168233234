import { MentoringInputBox } from 'parts/MentoringInputBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

interface ITODOInputField {
  todo: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TODOInputField = ({ todo, onChange }: ITODOInputField) => {
  return (
    <MentoringInputBox
      icon={<SquareRoundedIcon style={{ color: 'hsl(80, 90%, 85%)' }} />}
      value={todo}
      onChange={onChange}
      hoverBorderColor="hsl(80, 40%, 80%)"
      focusedBorderColor="hsl(80, 80%, 70%)"
      title="やると決まったこと"
      multiline
      minRows={4}
    />
  );
};
