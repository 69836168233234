import { Box } from '@mui/material';
import { Header } from 'components/Header';
import { SelectClient } from 'components/SelectClient';
import { UserTabs } from 'components/UserTabs';
import { useAuth, useRedirectIfAdmin, } from 'hooks';
import {
  Outlet,
  Navigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { useStore } from 'contexts';
import { useEffect } from 'react';

export const FamilyLayout = () => {
  const { currentUser } = useAuth();
  useRedirectIfAdmin();
  const { pathname } = useLocation();
  const { familyInfo } = useStore();

  if (familyInfo.status === 'idle') {
    return <>
      <Header title="" />
      <Loading />
    </>;
  }

  if (familyInfo.status === 'loading') {
    return (
      <>
        <Header title="" />
        <Loading />
      </>
    );
  }

  if (familyInfo.status === 'failed') {
    return (
      <>
        <Header title="" />
        <div>{familyInfo.error}</div>
      </>
    );
  }

  if (currentUser === null) {
    return <Navigate to="/signin" />;
  }


  return (
    <>
      <Header title="" />
      <Box
        sx={{
          display: 'flex',
          borderBottom: 'solid 1px #ddd',
          alignItems: 'center',
          mt: { xs: 0, sm: 0.5 },
        }}
      >
        {familyInfo?.data?.family &&
          <SelectClient
            clients={familyInfo.data?.clients ?? []}
            family={familyInfo?.data?.family}
          />
        }

        <UserTabs initialTabValue={pathname} />
      </Box>
      <Outlet />
    </>
  );
};
