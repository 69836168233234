import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import {
  AddMentoringSheetDialog,
  AlignCenterGrid,
  DeleteMentoringSheetDialog,
  Loading,
  MentoringSheetForm,
  SelectSheet,
} from 'components';
import { useMentoringSheet, useToggle, isDeprecatedSheet } from 'hooks';
import { useState } from 'react';

export const AdminMentoringPage = () => {
  // TODO: set startAt and endAt
  const { clientId } = useParams();
  if (clientId === undefined) {
    throw new Error('clientId is undefined');
  }
  const [
    { sheet, sheets, isLoading, error },
    { create, save, edit, select, publish, deleteSheet },
  ] = useMentoringSheet(clientId, false);
  const [openAddMentoringSheetDialog, toggleOpenAddMentoringSheetDialog] =
    useToggle(false);
  const [openDeleteMentoringSheetDialog, toggleOpenDeleteMentoringSheetDialog] =
    useToggle(false);
  const [newSheetMeetingDate, setNewSheetMeetingDate] = useState<Date>(() => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <div>エラーが発生しました</div>;
  }
  return (
    <AlignCenterGrid>
      <Box display="flex" sx={{ px: 2 }}>
        {sheet === undefined ? (
          <Typography>シートがありません</Typography>
        ) : (
          <SelectSheet
            sheets={sheets}
            sheet={sheet}
            onChange={e => select(e.target.value)}
          />
        )}
        <OpenAddNewSheetDialogButton
          onClick={() => {
            toggleOpenAddMentoringSheetDialog();
          }}
        />
        {sheet && !isDeprecatedSheet(sheet) ? (
          <PublishButton isDraft={sheet.isDraft} onClick={publish} />
        ) : null}
      </Box>
      {sheet ? (
        <>
          <MentoringSheetForm sheet={sheet} edit={edit} save={save} />
          <hr style={{ marginTop: 24 }} />
          <OpenDeleteSheetDialogButton
            onClick={toggleOpenDeleteMentoringSheetDialog}
          />
        </>
      ) : null}
      <AddMentoringSheetDialog
        open={openAddMentoringSheetDialog}
        meetingDate={newSheetMeetingDate}
        setMeetingDate={d => setNewSheetMeetingDate(d)}
        create={() => create(newSheetMeetingDate)}
        onClose={toggleOpenAddMentoringSheetDialog}
      />
      <DeleteMentoringSheetDialog
        open={openDeleteMentoringSheetDialog}
        deleteSheet={deleteSheet}
        onClose={toggleOpenDeleteMentoringSheetDialog}
      />
    </AlignCenterGrid>
  );
};

const PublishButton = ({
  isDraft,
  onClick,
}: {
  isDraft: boolean;
  onClick: () => void;
}) =>
  isDraft ? (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      sx={{ mx: 1 }}
      onClick={onClick}
    >
      お客様に反映
    </Button>
  ) : (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      sx={{ mx: 1 }}
      disabled
    >
      反映済み
    </Button>
  );

const OpenAddNewSheetDialogButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="contained"
    color="secondary"
    size="small"
    sx={{ mx: 1, minWidth: 24 }}
    onClick={onClick}
  >
    新しいG-POP&reg;シートを作成
  </Button>
);

const OpenDeleteSheetDialogButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="contained"
    color="warning"
    size="large"
    sx={{ mx: 2, minWidth: 24, mb: 12 }}
    onClick={onClick}
  >
    シートを削除
  </Button>
);

const EmptySheetPage = ({}) => {};
